import { produce } from 'immer';
import { SnackbarAction, SnackbarActionType } from './snackbar-actions';
import { SnackbarState } from './snackbar-state';

export const snackbarStateReducer = produce(
  (state: SnackbarState, action: SnackbarAction) => {
    switch (action.type) {
      case SnackbarActionType.SHOW: {
        state.message.title = action.payload.message.title;
        state.message.text = action.payload.message.text;
        state.messageType = action.payload.type;
        state.show = true;
        return state;
      }
      case SnackbarActionType.HIDE: {
        state.show = false;
        return state;
      }
      default: {
        return state;
      }
    }
  },
);
