const Form = {
  id: '8e95f34b59a94eecac385790ae4857b0',
  title: 'free-form-test',
  creator: 'konicaminoltamarketplace_60a507f5ff82a85efc5e1c2dbd4b90981f68ecdd',
  editor: 'konicaminoltamarketplace_60a507f5ff82a85efc5e1c2dbd4b90981f68ecdd',
  valid: true,
  advanced: true,
  metadataKeys:
    'text1,number1,textarea1,dropdown1,checkbox1,square1,circle1,triangle1,star1,diamond1,asterisk1,plus1,minus1,line1',
  created: 1716316264141,
  updated: 1717686555720,
  formStatus: 'published',
  formGroup: {
    id: 'ed956ae14e3e4a87bedfce09a4a7e097',
  },
  formDefinition: {
    targetMfpId: 'custom',
    config: {
      customCSS: `
.kmbs-input-adornment {
 filter:invert(88%) !important;
}
.my-text-field .kmbs-form-control {
  background-color: rgba(80, 227, 195, 0.34);
}
`,
      pageCount: 1,
      gridSize: 1,
      styling: {
        width: {
          num: 800,
          unit: 'px',
        },
        height: {
          num: 800,
          unit: 'px',
        },
        backgroundColor: '#ffffffff',
      },
    },
    pages: [
      {
        id: 'dH88r3qnru_xofNbNRifP',
        config: {
          styling: {
            backgroundColor: '#CCFFF5',
            backgroundImage: {
              enabled: true,
              url: 'https://i.imgur.com/eipZzxz_d.webp?maxwidth=760&fidelity=grand',
              opacity: 10,
              fitType: 'fit',
            },
          },
        },
      },
      {
        id: 'eI88r3qnru_xofNbNRifQ',
        config: {
          styling: {
            backgroundColor: '#FFCCD6',
            backgroundImage: {
              enabled: false,
              image: null,
              opacity: 100,
              fitType: 'fit',
            },
          },
        },
      },
    ],
    fields: [
      {
        type: 'pagebreak',
        id: 'GVUTx5L-7GU3-bVgZk6Ln',
        config: {
          variable: 'page1',
          title: '',
          hideBack: false,
          hideNext: false,
          hideScan: false,
          hideHome: false,
          hideReturn: false,
          hidePageNumbers: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 0,
            y: 0,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 1,
            height: 1,
          },
        },
      },
      {
        type: 'text',
        id: 'MQOXM97RORiYZg8QEmqpf',
        config: {
          variable: 'text1',
          title: 'Text line field friendly name goes here',
          defaultValue: '',
          minLength: '0',
          maxLength: '1000',
          required: false,
          readonly: true,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: 'my-text-field',
          coordinates: {
            x: 40,
            y: 650,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: 'red',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            labelPosition: 'bottom',
            fontSize: 35,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'underline',
            textAlign: 'left',
            width: 360,
            height: 40,
          },
        },
      },
      {
        type: 'date',
        id: 'Ms70eVqmBJclylJNU6MYk',
        config: {
          variable: 'date1',
          title: 'Label inside top of field',
          defaultValue: '',
          minValue: '08/01/2023 10:00 am',
          maxValue: '09/05/2029 12:30 pm',
          value: '06/26/2024 12:30 pm',
          returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
          defaultToNow: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 155,
            y: 150,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'right',
            width: 300,
            height: 40,
            labelPosition: 'within-top',
            labelStyling: {
              fontSize: 12,
            },
          },
        },
      },
      {
        type: 'number',
        id: '_NmwJcUK2ZnqGr57gShBi',
        config: {
          variable: 'number1',
          title: 'Number field friendly name goes here',
          defaultValue: '',
          minValue: '0',
          maxValue: '99999999',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 317,
            y: 135,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 30,
            borderRadius: 16,
            borderColor: 'brown',
            fontSize: 64,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 200,
            labelPosition: 'within-top',
            labelStyling: {
              color: 'green',
              backgroundColor: 'blue',
              fontFamily: 'Sans-serif',
              fontSize: 24,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
          },
        },
      },
      {
        type: 'textarea',
        id: 'Ms70eVqmBJclylJNUHMYk',
        config: {
          variable: 'textarea1',
          title: 'Text area field friendly name goes here',
          defaultValue: '',
          minLength: '0',
          maxLength: '5000',
          required: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 55,
            y: 222,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'dashed',
            borderWidth: 4,
            borderRadius: 0,
            borderColor: 'orange',
            fontSize: 48,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            labelPosition: 'left',
            labelStyling: {
              color: '#666666ff',
              backgroundColor: '#ffffff00',
              fontFamily: 'Sans-serif',
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
            width: 360,
            height: 400,
          },
        },
      },
      {
        type: 'dropdown',
        id: 'UNmGvge6d4k0g6jAzst5n',
        config: {
          variable: 'dropdown1',
          title: 'Drop-Down field friendly name goes here',
          defaultValue: '',
          required: false,
          readonly: false,
          multiSelect: false,
          buttonsList: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          options: [
            {
              id: 'VLhQaJ70kLb6UZwZ5iB_D',
              label: 'one',
              value: '1',
              show: true,
              isDefault: false,
            },
            {
              id: 'wdMOpdaYJJXISV9K6FPeY',
              label: 'two',
              value: '2',
              show: true,
              isDefault: false,
            },
          ],
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 74,
            y: 437,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
            labelPosition: 'bottom',
            labelStyling: {
              color: '#666666ff',
              backgroundColor: 'blue',
              fontFamily: 'Sans-serif',
              fontSize: 32,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
          },
        },
      },
      {
        type: 'checklist',
        id: '9enGRkUEpPFYP8cC3sgen',
        config: {
          variable: 'checklist1',
          title: 'Checkmark field friendly name goes here',
          defaultValue: 'defaultValue',
          readonly: false,
          multiSelect: true,
          required: false,
          checked: false,
          buttonsList: false,
          excludeFromMetadata: false,
          showHelp: false,
          vertical: true,
          helpText: '',
          options: [
            {
              id: 'checkbox-id-1',
              label: 'label-1',
              value: 'value-1',
              show: true,
              isChecked: true,
            },
            {
              id: 'checkbox-id-2',
              label: 'label-2',
              value: 'value-2',
              show: true,
              isChecked: false,
            },
          ],
          labelPlacement: 'end',
          customClassName: '',
          locked: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          groupId: undefined,
          coordinates: {
            x: 100,
            y: 150,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontFamily: 'Sans-serif',
            fontSize: 22,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 300,
            height: 48,
            labelPosition: 'right',
            labelStyling: {
              color: '#666666ff',
              backgroundColor: '#ffffff00',
              fontFamily: 'Sans-serif',
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
          },
        },
      },
      {
        type: 'signature',
        id: 'eksZ5Uc7v__JjJLjcfTmy',
        config: {
          variable: 'signature1',
          title: 'Signature',
          defaultValue: 'Hello',
          minLength: '0',
          maxLength: '1000',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          customClassName: '',
          locked: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 91,
            y: 100,
          },
          drawSign: true,
          typeSign: true,
          uploadImageSign: true,
          styling: {
            rotate: '0deg',
            color: 'red',
            backgroundColor: 'yellow',
            borderStyle: 'solid',
            borderWidth: 6,
            borderRadius: 20,
            borderColor: '#c4c4c4ff',
            fontFamily: 'Yellowtail',
            fontSize: 24,
            fontWeight: 'bold',
            fontStyle: 'italic',
            textDecorationLine: 'none',
            textAlign: 'right',
            labelPosition: 'within-top',
            labelStyling: {
              color: 'pink',
              backgroundColor: 'blue',
              fontFamily: 'Sans-serif',
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
            width: 538,
            height: 60,
          },
        },
      },
      {
        type: 'buttonlist',
        id: '5nJry9ZluDvRRmQCQiSLh',
        config: {
          variable: 'buttonlist1',
          title: 'Button list friendly name goes here',
          defaultValue: '',
          readonly: false,
          multiSelect: false,
          required: false,
          excludeFromMetadata: false,
          showHelp: false,
          vertical: false,
          helpText: '',
          options: [
            {
              id: 'j5gxQ5-lD4IFObkpYD05x',
              label: 'button1',
              value: 'submit1',
              show: true,
              isChecked: false,
            },
            {
              id: '921fiQ3yx9xymC8L7EMmD',
              label: 'button2',
              value: 'submit2',
              show: true,
              isChecked: false,
            },
            {
              id: '921fiQ3yx9xymC8L7EMmE',
              label: 'button3',
              value: 'submit3',
              show: true,
              isChecked: false,
            },
            {
              id: '921fiQ3yx9xymC8L7EMmF',
              label: 'button3withlonglabel',
              value: 'submit3',
              show: true,
              isChecked: false,
            },
            {
              id: '921fiQ3yx9xymC8L7EMmG',
              label: 'button3',
              value: 'submit3',
              show: true,
              isChecked: false,
            },
          ],
          labelPlacement: 'end',
          customClassName: '',
          locked: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 90,
            y: 264,
          },
          styling: {
            rotate: '0deg',
            color: '#ffffffff',
            backgroundColor: '#5f3fa5ff',
            borderStyle: 'solid',
            borderWidth: 3,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontFamily: 'Sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            labelPosition: 'top',
            labelStyling: {
              color: '#666666ff',
              backgroundColor: '#ffffff00',
              fontFamily: 'Sans-serif',
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
            width: 143,
            height: 72,
          },
        },
        selected: true,
      },
      {
        type: 'file',
        id: 'UCIW8i-tp4Vk0fPL90k8K',
        config: {
          variable: 'file1',
          title: 'Attach File field friendly name goes here',
          fileTypes: 'pdf, tif, png, jpg',
          maxFileSize: '5',
          maxFileSizeUnits: 'MB',
          allowMultipleFiles: false,
          required: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          customClassName: '',
          locked: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 264,
            y: 18,
          },
          styling: {
            rotate: '0deg',
            color: 'green',
            backgroundColor: 'blue',
            borderStyle: 'solid',
            borderWidth: 14,
            borderRadius: 30,
            borderColor: 'red',
            fontFamily: 'Sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            labelPosition: 'within-top',
            width: 300,
            height: 80,
            labelStyling: {
              color: 'red',
              backgroundColor: 'transparent',
              fontFamily: 'Sans-serif',
              fontSize: 24,
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecorationLine: 'none',
              textAlign: 'left',
            },
          },
        },
      },
      {
        type: 'square',
        id: 'Jg_Zq_a_zLDzkA2ovVJQc',
        config: {
          variable: 'square1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 52,
            y: 541,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: 'red',
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 50,
            height: 50,
          },
        },
      },
      {
        type: 'circle',
        id: 'xS-R_NPuvvTI5QmWfkDol',
        config: {
          variable: 'circle1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 167,
            y: 524,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 10,
            borderRadius: 0,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 100,
            height: 100,
          },
        },
      },
      {
        type: 'triangle',
        id: 'v-ax1V1j9-awse-WzZD-9',
        config: {
          variable: 'triangle1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 284,
            y: 532,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 10,
            borderRadius: 0,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 112,
            height: 91,
          },
        },
      },
      {
        type: 'star',
        id: 'ouajMla_trwlyn1q449be',
        config: {
          variable: 'star1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 420,
            y: 530,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 0,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 96,
            height: 96,
          },
        },
      },
      {
        type: 'diamond',
        id: 'dad_TdNE4up_xcb3f3zvp',
        config: {
          variable: 'diamond1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 632,
            y: 527,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 10,
            borderRadius: 0,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 124,
            height: 102,
          },
        },
      },
      {
        type: 'asterisk',
        id: 'Qu9u608vLLg_U5CjqHV_Y',
        config: {
          variable: 'asterisk1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 531,
            y: 522,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 10,
            borderRadius: 1,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 91,
            height: 112,
          },
        },
      },
      {
        type: 'plus',
        id: 'DYJuDNWkWlVBlAvxAkKvZ',
        config: {
          variable: 'plus1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 144,
            y: 658,
          },
          styling: {
            rotate: '0deg',
            color: 'red',
            backgroundColor: 'yellow',
            borderStyle: 'solid',
            borderWidth: 10,
            borderRadius: 5,
            borderColor: 'red',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 100,
            height: 100,
          },
        },
      },
      {
        type: 'minus',
        id: 'hTEW6i9TdM3odJYs4ebe9',
        config: {
          variable: 'minus1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 143,
            y: 701,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 10,
            borderRadius: 0,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 60,
            height: 60,
          },
        },
      },
      {
        type: 'line',
        id: 'TpWRtcnRDG4whrWenDPxR',
        config: {
          variable: 'line1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 200,
            y: 701,
          },
          styling: {
            rotate: '-90deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 60,
            height: 60,
          },
        },
      },
      {
        type: 'label',
        id: 'AgAySTUWcaPT5pho6veyC',
        config: {
          variable: 'label1',
          title:
            'Label goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes here',
          pageId: 'dH88r3qnru_xofNbNRifP',
          customClassName: '',
          coordinates: {
            x: 342,
            y: 395,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'dashed',
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#c4c4c4ff',
            fontSize: 20,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 88,
          },
        },
      },
      {
        type: 'image',
        id: 'AgAySTUWcaPT5pho6veyCC',
        value:
          'https://cdn.vox-cdn.com/thumbor/NFOSIeq8OLe14_bQTSWxoi-TB2Y=/0x529:1267x1374/920x613/filters:focal(0x529:1267x1374):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/49580341/h1.0.0.jpg',
        config: {
          variable: 'image1',
          title: 'my image',
          pageId: 'dH88r3qnru_xofNbNRifP',
          opacity: 0.5,
          coordinates: {
            x: 400,
            y: 600,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'dashed',
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#c4c4c4ff',
            fontSize: 20,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 200,
            height: 150,
          },
        },
      },
    ],
  },
};
export default Form;
