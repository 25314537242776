import { SvgIconProps, SvgIcon } from "@material-ui/core";

export const SignatureUploadBackgroundIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 76 76" {...props}>
		<g id="Group_7946" data-name="Group 7946" transform="translate(928.771 4283.303)">
			<g id="Group_3172" data-name="Group 3172" transform="translate(-1128.539 -4775.303)">
				<g id="Group_6951" data-name="Group 6951" transform="translate(2.768)">
					<g id="Rectangle_1474" data-name="Rectangle 1474" transform="translate(200 495)" fill="#f2eff9" stroke="#f2eff9" stroke-width="0.5">
						<rect width="69.535" height="69.535" stroke="none" />
						<rect x="0.25" y="0.25" width="69.035" height="69.035" fill="none" />
					</g>
					<path id="image_FILL0_wght400_GRAD0_opsz48" d="M9.551,42.769H73.258L54.107,17.234,36.91,39.512,24.794,22.967Z" transform="translate(193.365 514.618)" fill="#dfd8ef" />
				</g>
			</g>
		</g>
		<rect id="Rectangle_3174" data-name="Rectangle 3174" width="76" height="76" fill="none" />
	</SvgIcon>
);