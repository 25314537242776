import { produce } from 'immer';
import { AppState } from '../../../interfaces';
import { AppActions } from './app-state-actions';

export enum AppActionsEnum {
  SET_CONFIG,
  TOGGLE_DEBUG,
}

export const AppReducer = produce((state: AppState, action: AppActions) => {
  switch (action.type) {
    case AppActionsEnum.SET_CONFIG: {
      state = { ...state, ...action.payload };
      return state;
    }
    case AppActionsEnum.TOGGLE_DEBUG: {
      state.debug = !state.debug;
      return state;
    }
    default:
      return state;
  }
});
