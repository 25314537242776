import { Grid, Typography, Button } from '@material-ui/core';
import Layout from './layout';
import SuccessIcon from '../../assets/submitted.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface successStateType {
  prvUrl: string;
}

function Success() {
  const { t } = useTranslation();
  const { state: successState } = useLocation<successStateType>();

  return (
    <Layout onSubmit={() => { }}>
      <Grid
        container
        direction="column"
        item
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item className="m-2">
          <img src={SuccessIcon} alt="success icon" />
        </Grid>
        <Grid item className="m-2">
          <Typography variant="h4">
            {'Thank you for your submission!'}
          </Typography>
        </Grid>
        <Grid item className="m-2">
          <Typography variant="body1">
            {'Would you like to make another submission?'}
          </Typography>
        </Grid>
        <Grid item className="m-2">
          <Button
            onClick={() => {
              window.location.href = successState.prvUrl;
            }}
            style={{
              backgroundColor: '#5f3fa5',
              color: 'white',
              borderRadius: 4,
              fontWeight: 600,
            }}
            variant="contained"
          >
            {t('Submit Another')}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Success;
