import { FC, memo } from 'react';
import { FormField, FormFieldTypeEnum } from '../../interfaces/FormFieldTypes';
import GeneralTextField from './FormTextField';
import NumberField from './NumberField';
import PasswordField from './PasswordField';
import TextAreaField from './TextArea';
import HelperTooltip from '../mfp/HelperTooltip';
import { Grid } from '@material-ui/core';
import LabelField from './LabelField';
import CheckboxField from './CheckboxField';
import ChecklistField from './ChecklistField';
import RadiogroupField from './RadiogroupField';
import DropdownField from './DropdownField';
import DateTimeField from './DateTimeField';
import PageBreakField from './PageBreakField';
import FileField from './FileField';
import ShapeTool from './ShapeTool';
import TextTool from './TextTool';
import ImageTool from './ImageTool';
import SignatureField from './SignatureField';
import ButtonListField from './ButtonListField';

interface FieldProps {
  field: FormField;
  viewOnly?: boolean;
}

const Field: FC<any> = memo((props: FieldProps) => {
  switch (props.field.type) {
    case FormFieldTypeEnum.text:
      return <GeneralTextField field={props.field} />;
    case FormFieldTypeEnum.number:
      return <NumberField field={props.field} />;
    case FormFieldTypeEnum.textarea:
      return <TextAreaField field={props.field} />;
    case FormFieldTypeEnum.password:
      return <PasswordField field={props.field} />;
    case FormFieldTypeEnum.label:
      return <LabelField field={props.field} />;
    case FormFieldTypeEnum.checkbox:
      return <CheckboxField field={props.field} />;
    case FormFieldTypeEnum.checklist:
      return <ChecklistField field={props.field} />;
    case FormFieldTypeEnum.radiogroup:
      return <RadiogroupField field={props.field} />;
    case FormFieldTypeEnum.buttonlist:
      return <ButtonListField field={props.field} />;
    case FormFieldTypeEnum.dropdown:
      return <DropdownField field={props.field} />;
    case FormFieldTypeEnum.datetime:
      return <DateTimeField field={props.field} />;
    case FormFieldTypeEnum.pageBreak:
      return <PageBreakField field={props.field} />;
    case FormFieldTypeEnum.hidden:
      return <></>;
    case FormFieldTypeEnum.file:
      return <FileField field={props.field} />;
    case FormFieldTypeEnum.signature:
      return <SignatureField field={props.field} />;

    //advanced form fields and tools
    case FormFieldTypeEnum.square:
    case FormFieldTypeEnum.circle:
    case FormFieldTypeEnum.triangle:
    case FormFieldTypeEnum.star:
    case FormFieldTypeEnum.asterisk:
    case FormFieldTypeEnum.diamond:
    case FormFieldTypeEnum.minus:
    case FormFieldTypeEnum.plus:
    case FormFieldTypeEnum.line:
      return <ShapeTool field={props.field} />;
    case FormFieldTypeEnum.texttool:
      return <TextTool field={props.field} />;
    case FormFieldTypeEnum.imagetool:
      return <ImageTool field={props.field} />;
    default:
      console.error('Unknown field type', props.field);
      return <></>;
  }
});

const FieldRow: FC<any> = (props: FieldProps) => {
  if (!props.field.visible) return <></>;
  return (
    <Grid container item xs={12} key={props.field.id + `_fragment`} className={props.field.config.customClassName}>
      <Grid
        className={`${props.viewOnly ? 'pointer-events-none' : ''}`}
        item
        xs={props.field.config.styling?.width ? undefined : 11}
      >
        <Field field={props.field} />
      </Grid>
      {props.field.config.showHelp && (
        <Grid item xs={1}>
          <div className={props.field.type === FormFieldTypeEnum.checkbox ? 'flex h-full' : ''}>
            <HelperTooltip message={props.field.config.helpText} tool={props.field.config.title} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(FieldRow);
