import { Button, Typography } from '@material-ui/core';
import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@dispatcher-stratus/stratus-react/dist/assets/home.svg';
import NextIcon from '@dispatcher-stratus/stratus-react/dist/assets/next.svg';
import RefreshIcon from '@dispatcher-stratus/stratus-react/dist/assets/refresh.svg';
import { useFormData } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { useQueryClient } from 'react-query';
import { AppContext } from './context/AppState/AppContext';
import { trackPromise } from 'react-promise-tracker';
import { useAppArgs } from './hooks/useAppArgs';

export declare interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode; // best, accepts everything React can render
  hideTitle?: boolean;
  hideHome?: boolean;
  hideRefresh?: boolean;
  hideBack?: boolean;
  hideNext?: boolean;
  disableSubmit?: boolean;
  inIFrame?: boolean;
  onSubmit?: () => Promise<void>;
}

const ActionBar = (props: Props): JSX.Element => {
  const { state: formState } = useFormData();
  const { hideTitle, hideHome, hideRefresh, hideBack, hideNext, disableSubmit, inIFrame, onSubmit, ...otherProps } =
    props;

  return (
    <div {...otherProps} className={props.className + ' w-full flex justify-between items-center'}>
      <div className="flex h-40px items-center w-full justify-between pr-2">
        {!hideTitle && <Title title={formState.currentPage.config.title} />}
      </div>
      <div className="flex h-40px space-x-2.5">
        {!hideHome && !inIFrame && <HomeButton hide={formState?.currentPage?.config?.hideHome} />}
        {!hideRefresh && <RefreshButton hide={formState?.currentPage?.config?.hideReturn} />}
        {!hideBack && <BackButton hide={formState?.currentPage?.number === 1} />}
        {!hideNext && <NextButton disableSubmit={disableSubmit} onSubmit={onSubmit} />}
      </div>
    </div>
  );
};

const Title = memo(({ title }: { title: string }) => {
  const [clicks, setClicks] = useState(0);
  const { state, setAppConfig } = useContext(AppContext);
  return (
    <Typography
      variant="h6"
      color="textPrimary"
      style={{ fontSize: '22px' }}
      onClick={() => {
        if (clicks === 4) {
          setClicks(0);
          console.log('Debug toggled:', !state.debug ? 'On' : 'Off');
          setAppConfig({ debug: !state.debug });
        } else {
          setClicks((val) => ++val);
        }
      }}
    >
      {`${title}`}
    </Typography>
  );
});

const HomeButton = ({ hide }: { hide: boolean }) => {
  const { domain } = useAppArgs();
  const { t } = useTranslation();
  return (
    <Button
      style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
      variant="contained"
      color="secondary"
      onClick={() => {
        window.location.href = `https://mfp.${domain}`;
      }}
      hidden={hide}
    >
      <img src={HomeIcon} alt={t('Home')} />
    </Button>
  );
};
const RefreshButton = memo(({ hide }: { hide: boolean }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return (
    <Button
      style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
      variant="contained"
      color="secondary"
      onClick={() => {
        queryClient.invalidateQueries('formUrlData');
        queryClient.invalidateQueries('formData');
      }}
      hidden={hide}
    >
      <img src={RefreshIcon} alt={t('Refresh')} />
    </Button>
  );
});
const BackButton = memo(({ hide }: { hide: boolean }) => {
  const { t } = useTranslation();
  const { dispatch: formDispatch } = useFormData();
  return (
    <Button
      style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
      variant="contained"
      color="secondary"
      onClick={() => {
        formDispatch({
          type: FormActionType.DECREMENT_PAGE,
        });
        document.querySelector('#formGrid')?.children[0].scrollIntoView();
      }}
      disabled={hide}
    >
      <img style={{ transform: 'rotate(180deg)' }} src={NextIcon} alt={t('Back')} />
    </Button>
  );
});
const NextButton = memo(
  ({ onSubmit, disableSubmit = false }: { onSubmit?: () => Promise<void>; disableSubmit?: boolean }) => {
    const { t } = useTranslation();
    const { state: formState, dispatch: formDispatch } = useFormData();
    return (
      <Button
        style={{ width: '87px', paddingTop: '1px', paddingBottom: '1px' }}
        variant="contained"
        color={formState.valid && formState.currentPage.number === formState.numPages ? 'primary' : 'secondary'}
        onClick={() => {
          if (formState.currentPage.number < formState.numPages) {
            formDispatch({
              type: FormActionType.INCREMENT_PAGE,
            });
            document.querySelector('#formGrid')?.children[0].scrollIntoView();
          } else {
            if (!formState.valid) return;
            if (onSubmit) trackPromise(onSubmit());
          }
        }}
        disabled={formState.currentPage.number === formState.numPages && (!formState.valid || disableSubmit)}
      >
        <img src={NextIcon} alt={t('Next')} />
      </Button>
    );
  },
);

export default memo(ActionBar);
