import { nanoid } from 'nanoid';
import { Slide, SlideProps, Snackbar } from '@material-ui/core';
import { useSnackbar } from './hooks/useSnackbar';
import { useSnackbarApi } from './hooks/useSnackbarApi';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

export const AppSnackbar = () => {
  const { state } = useSnackbar();
  const { hideMessage } = useSnackbarApi();

  const handleClose = () => {
    hideMessage();
  };

  function TransitionRight(props: JSX.IntrinsicAttributes & SlideProps) {
    return <Slide {...props} direction="right" />;
  }

  return (
    <Snackbar
      key={nanoid()}
      open={state.show}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={TransitionRight}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={state.messageType}
        style={{ width: '100%' }}
      >
        {state.message.title && <AlertTitle>{state.message.title}</AlertTitle>}
        {state.message.text}
      </Alert>
    </Snackbar>
  );
};
