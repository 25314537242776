import { SvgIconProps, SvgIcon } from "@material-ui/core";

export const DeleteIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 22 22" {...props}>
		<g id="delete">
			<path
				fill="currentColor"
				d="M5.2,18.6c0,1.1,0.8,2.1,2,2.2h7.9c1.1-0.1,2-1,2-2.2v-13H5.2V18.6z M7.2,7.8h7.9v10.8H7.2V7.8z M14.6,2.4
		l-1-1.1h-5l-1,1.1H4.2v2.2h13.9V2.4H14.6z"
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.3883' }}
				x1="9.8"
				y1="10.4"
				x2="9.8"
				y2="18.7"
			/>
			<line
				style={{ fill: 'none', stroke: 'currentColor', strokeWidth: '1.3883' }}
				x1="12.6"
				y1="10.4"
				x2="12.6"
				y2="18.7"
			/>
		</g>
	</SvgIcon>
);