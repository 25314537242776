export declare interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode; // best, accepts everything React can render
}

export const Body = (props: Props): JSX.Element => {
  return (
    <div {...props} className={props.className + ' flex'}>
      {props.children}
    </div>
  );
};
