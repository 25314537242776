import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
const urlParams = new URLSearchParams(window.location.search);
const axiosMockInstance = axios.create({
  responseType: 'json',
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    },
  ],
  headers: {
    'Content-Type': 'application/json',
  },
});
axiosMockInstance.interceptors.request.use((config) => {
  config.url = config.url?.split('/')?.slice(3).join('/');
  console.log('[MOCK] Axios Mock Request', config.url, config.data);
  return config;
});
axiosMockInstance.interceptors.response.use((response) => {
  console.log('[MOCK] Axios Mock Response', response.config.url, response.status, response.data);
  return response;
});

const axiosLiveInstance = axios.create({
  responseType: 'json',
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    },
  ],
  headers: {
    'Content-Type': 'application/json',
  },
});
export const axiosMockAdapterInstance = new AxiosMockAdapter(axiosMockInstance, {
  delayResponse: 300,
  onNoMatch: 'passthrough',
});
export default urlParams.has('mock') ? axiosMockInstance : axiosLiveInstance;
