import { DOMAIN, REGION } from '../interfaces/AppState';
import axios from './axios';

const domain: DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
const getUrl = (domain: DOMAIN, region: REGION): string => {
  if (domain === 'localhost') domain = 'stratus.lol';
  return `https://${region}.ntf.${domain}`;
};

export async function getUserPool(
  slug: string,
  domain: DOMAIN,
  region: REGION,
) {
  const response = await axios.post(`${getUrl(domain, region)}/userpool/all`, {
    slug,
    region,
  });
  console.log('user pool', response.data);
  return response.data;
}

export async function startWorkflow(
  workflowId: string,
  nodeId: string,
  tenant: {
    id: string;
    name: string;
    region: string;
    slug: string;
    plan: string;
  },
  token: string,
) {
  console.log('asdasdasdasdas');
  const response = await axios.post(
    `${getUrl(domain, tenant.region)}/workflow/start`,
    {
      workflowId,
      nodeId,
      tenant,
      dipa: false,
      hash: 'none',
      metadata: {
        stratus: true,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export async function checkWorkflowStatus(
  arn: string,
  region: string,
  token: string,
): Promise<{
  arn: string;
  token: string;
  callbackUrl: string;
  processing: boolean;
}> {
  const response = await axios.post(
    `${getUrl(domain, region)}/workflow/ui`,
    {
      arn,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export async function awaitWorkflowReady(
  arn: string,
  region: string,
  token: string,
): Promise<{
  arn: string;
  token: string;
  callbackUrl: string;
}> {
  return new Promise(async (resolve, reject) => {
    const intervalId = setInterval(async () => {
      try {
        console.log('fetching workflow instance...');
        const instanceData = await checkWorkflowStatus(arn, region, token);
        if (instanceData.processing) {
          console.error('still processing..');
          return;
        }
        resolve(instanceData);
        clearInterval(intervalId);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    }, 1000);
  });
}
