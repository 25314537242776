import { TextTool as TextToolType } from '../../interfaces/FormFieldTypes';
import { memo } from 'react';

type Props = {
  field: TextToolType;
};
const TextTool = (props: Props) => {
  return (
    <div
      className="flex flex-col py-2 whitespace-pre-wrap"
      style={{ ...props.field.config.styling, rotate: undefined, lineHeight: '1.2em', overflow: 'hidden' }}
    >
      {props.field.config.title}
    </div>
  );
};

export default memo(TextTool);
