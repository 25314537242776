import { SvgIconProps, SvgIcon } from "@material-ui/core";

export const SignatureDrawBackgroundIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 82 76" {...props}>
		<g id="Group_6960" data-name="Group 6960" transform="translate(2420.931 4574.999)">
			<g id="Group_6958" data-name="Group 6958" transform="translate(-2418.483 -4565.462)">
				<path id="Path_7936" data-name="Path 7936" d="M77.1,46H16.85a78.893,78.893,0,0,0,2.882-8.278q.526-1.858.958-3.74A5.532,5.532,0,0,1,26.227,36.7a4.432,4.432,0,0,1,.311,3.126,6.539,6.539,0,0,0-1.72,2.665,1.167,1.167,0,0,0,.411,1.392c.912.611,2.852-.134,3.192-.417a6.84,6.84,0,0,0,1.84-2.381c1.209-.655,3.109-.591,5.67.207l.882.274A22.353,22.353,0,0,0,43.6,42.939h.033A3.138,3.138,0,0,0,45.979,41.6a1.151,1.151,0,0,0,.037-1.165,1.5,1.5,0,0,0-1.409-.628H44.6a19.66,19.66,0,0,1-5.52-1.132,17.133,17.133,0,0,0-7.116-1.129c-.394.04-.771.1-1.135.177-.007-.07-.017-.144-.027-.217a7.481,7.481,0,0,0-4.187-5.74,10.243,10.243,0,0,0-5.263-.945,79.137,79.137,0,0,0,1.356-13.788,62.765,62.765,0,0,0-.478-9.48c-.291-2.024-.935-4.892-3.142-6.442-4.331-3.035-9.767.661-13.1,4.745C1.376,11.513.257,18.756,3.139,24.313A14.471,14.471,0,0,0,16.092,32.3l.174,0a18.349,18.349,0,0,0-3.59,2.361A28.945,28.945,0,0,0,4.6,46H0v3.693H3.55c-.033.16-.083.324-.107.484a5.736,5.736,0,0,0,2.848,6.191,4.555,4.555,0,0,0,2.047.488,5.479,5.479,0,0,0,3.914-1.82,17.305,17.305,0,0,0,3.089-5.343H77.1ZM8.9,25.411A13.879,13.879,0,0,1,6.3,13.323c.925-3.376,3.857-8.669,7.487-9.861a3.57,3.57,0,0,1,1.085-.194,2.017,2.017,0,0,1,1.172.354c2.01,1.386,2.241,6.361,2.351,8.749l.023.481a75.826,75.826,0,0,1-.935,16.309l-.127.007A9.778,9.778,0,0,1,8.9,25.411m.965,18.637a24.888,24.888,0,0,1,6.151-8.011c-.25.962-.521,1.923-.808,2.878C14.476,41.347,13.6,43.7,12.656,46H8.933c.3-.671.614-1.329.935-1.95m.4,7.206-.264.538c-.431.9-.494,1.045-1.332,1.062a6.025,6.025,0,0,1-.785-.013,1.844,1.844,0,0,1-.237-.568,3.931,3.931,0,0,1-.08-1.423,8.793,8.793,0,0,1,.164-1.159H10.98c-.244.511-.451,1.059-.708,1.563" transform="translate(0 0)" fill="#f2eff9" />
				<g id="Group_6953" data-name="Group 6953" transform="translate(47.689 0.701)">
					<path id="Path_7937" data-name="Path 7937" d="M36.19,6.308a1.31,1.31,0,0,0-.751-1.553C32.928,3.4,31.675,2.718,29.164,1.363c-.371-.2-1.3-.417-1.71.214A147.16,147.16,0,0,0,15.473,24.317L23.7,28.775A147.747,147.747,0,0,0,36.19,6.308" transform="translate(-11.493 2.007)" fill="#f2eff9" />
					<path id="Path_7938" data-name="Path 7938" d="M14.769,22.6c.578.314,1.443-.094,2.484-1.439,0,0,2.595-.25,7.71-8.131L17.236,8.84c-3.907,8.762-2.625,10.913-2.625,10.913-.264.8-.658,2.4.157,2.845" transform="translate(-14.281 19.977)" fill="#f2eff9" />
					<path id="Path_7939" data-name="Path 7939" d="M20.723,16.884a1.344,1.344,0,0,0,1.88-.391A76.743,76.743,0,0,0,28.811,5.043a1.357,1.357,0,0,0-2.488-1.085A74.135,74.135,0,0,1,20.332,15a1.374,1.374,0,0,0,.391,1.88" transform="translate(-0.614 6.665)" fill="#f2eff9" />
					<path id="Path_7940" data-name="Path 7940" d="M26.145,3.432c.19-.354-.067-.872-.584-1.149l-3.5-1.9c-.511-.277-1.089-.217-1.282.14l-.755,1.392,5.37,2.909Z" transform="translate(-0.854 -0.21)" fill="#f2eff9" />
				</g>
			</g>
		</g>
		<rect id="Rectangle_3175" data-name="Rectangle 3175" width="82" height="76" fill="none" />
	</SvgIcon>
);