import { useFormData } from '../../context/FormState/FormDataContext';
import { FormActionType } from '../../context/FormState/form-state-reducer';
import PreviousPageIcon from '../../../assets/previous-page-navigation-arrow.svg';
import { NavigationButton } from './FooterControls';

function PreviousPageButton() {
  const { state: formState, dispatch } = useFormData();
  return (
    <NavigationButton
      onClick={() =>
        dispatch({
          type: FormActionType.DECREMENT_PAGE,
        })
      }
      disabled={formState.numPages < 2 || formState.currentPage.number < 2}
    >
      <img src={PreviousPageIcon} alt="go to previous page"></img>
    </NavigationButton>
  );
}

export default PreviousPageButton;
