import { memo, useContext } from 'react';
import Spinner from '../Spinner';
import ActionBar from '../actionBar';
import { Body } from './body';
import Header from './Header';
import { NodeContext, NodeContextType } from '@dispatcher-stratus/stratus-react';
import { DebugWindow } from '../DebugWindow';
import PageNumberChip from './PageNumberChip';
import { useFormData } from '../context/FormState/FormDataContext';
import { useAppConfig } from '../context/AppState/AppContext';
import FooterControls from '../embed/FooterControls/FooterControls';
import { useAppArgs } from '../hooks/useAppArgs';
import { useSubmitForm } from '../hooks/useSubmitForm';
import { Grid } from '@material-ui/core';
import ResetButton from '../embed/ResetButton';
import SaveButton from '../embed/SaveButton';
import FormTitle from '../embed/FormTitle';

export declare interface Props {
  hideHeader?: boolean;
  hideTitle?: boolean;
  hideActionBar?: boolean;
  print?: boolean;
  children?: React.ReactNode; // best, accepts everything React can render
  next?: string;
  back?: string;
  inIFrame?: boolean;
  onSubmit?: () => Promise<void>;
}

const Layout = (props: Props): JSX.Element => {
  const { state } = useContext<NodeContextType>(NodeContext);
  const { state: formState } = useFormData();
  const { state: appState } = useAppConfig();
  const { debug } = useAppArgs();
  const submit = useSubmitForm();

  const handleSubmitForm = async (): Promise<void> => {
    return submit();
  };

  return (
    <>
      <div
        id="layout-wrapper"
        style={{
          height: formState.advanced || props.print ? '' : '100vh',
          width: formState.advanced ? '' : '100vw',
        }}
        className="flex m-auto flex-col"
      >
        <Spinner />
        {formState.loaded && !formState.advanced && (
          <>
            {!props.hideHeader && (
              <div className="bg-white">
                <Header
                  className="h-50px bg-mfpHeader px-5 flex-shrink-0"
                  user={state.username}
                  tenant={state.tenant?.name}
                />
              </div>
            )}
            {!props.hideActionBar && (
              <ActionBar
                onSubmit={props.onSubmit}
                inIFrame={props.inIFrame}
                className="h-56px bg-mfpActionBar px-2.5 py-2 flex-shrink-0 pl-4"
              />
            )}
          </>
        )}
        {formState.loaded && !formState.advanced && props.hideActionBar && !props.hideTitle && (
          <div className="bg-white">
            <FormTitle />
          </div>
        )}
        <Body
          style={
            formState.advanced
              ? {
                  width: `${formState.formDefinition.config?.styling?.width.num}${formState.formDefinition.config?.styling?.width.unit}`,
                  minHeight: `${formState.formDefinition.config?.styling?.height.num}${formState.formDefinition.config?.styling?.height.unit}`,
                  margin: 'auto',
                  border: debug ? '1px solid red' : '',
                }
              : {}
          }
          className="justify-center bg-white overflow-x-auto h-full overflow-y-auto"
        >
          {props.children}
        </Body>
        {formState.currentPage.number === formState.numPages && !props.print && appState.displayAdvancedSubmit && (
          <Grid container justifyContent="center" className="my-10">
            <Grid item className="mx-5">
              <ResetButton />
            </Grid>
            <Grid item className="mx-5">
              <SaveButton onClick={handleSubmitForm} />
            </Grid>
          </Grid>
        )}
        {!formState.currentPage.config.hidePageNumbers ||
          (!formState.advanced && (
            <div className="absolute bottom-1 right-10">
              <PageNumberChip />
            </div>
          ))}
        {formState.loaded && props.hideActionBar && !props.print && <FooterControls />}
        <div>{appState.debug && <DebugWindow />}</div>
      </div>
    </>
  );
};

export default memo(Layout);
