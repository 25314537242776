import { CSSProperties } from 'react';

export enum FormFieldTypeEnum {
  number = 'number',
  text = 'text',
  textarea = 'textarea',
  checkbox = 'checkbox',
  checklist = 'checklist',
  radiogroup = 'radiogroup',
  buttonlist = 'buttonlist',
  pageBreak = 'pagebreak',
  password = 'password',
  dropdown = 'dropdown',
  datetime = 'date',
  hidden = 'hidden',
  label = 'label',
  file = 'file',
  square = 'square',
  circle = 'circle',
  triangle = 'triangle',
  star = 'star',
  diamond = 'diamond',
  asterisk = 'asterisk',
  plus = 'plus',
  minus = 'minus',
  line = 'line',
  texttool = 'textbox',
  imagetool = 'image',
  signature = 'signature',
}

export interface GenericFieldLabelStyling extends CSSProperties {
  color: string;
  backgroundColor: string;
  fontSize: number;
  fontWeight: 'bold' | 'normal';
  fontFamily: string;
  fontStyle: 'italic' | 'normal';
  textDecorationLine: 'underline' | 'none';
  textAlign: 'left' | 'right' | 'center' | 'justify';
}

export interface GenericFieldStyling extends CSSProperties {
  width: number;
  height: number;
  rotate: `${number}deg`;
  color: string;
  backgroundColor: string;
  borderStyle: 'dashed' | 'dotted' | 'solid';
  borderWidth: number;
  borderRadius: number;
  borderColor: string;
  fontSize: number;
  fontFamily: string;
  fontWeight: 'bold' | 'normal';
  fontStyle: 'italic' | 'normal';
  textDecorationLine: 'underline' | 'none';
  textAlign: 'left' | 'right' | 'center' | 'justify';
  labelPosition: 'top' | 'inside-top' | 'within-top' | 'left' | 'right' | 'bottom';
  labelStyling: GenericFieldLabelStyling;
}

export type GenericFieldConfig = {
  title: string;
  defaultValue: any;
  required: boolean;
  variable: string;
  readonly: boolean;
  showHelp: boolean;
  helpText: string;
  customClassName?: string;
  pageId?: string;
  styling?: GenericFieldStyling;
  coordinates?: {
    x: number;
    y: number;
  };
  excludeFromMetadata: boolean;
};

export type GenericField = {
  id: string;
  value: any;
  type: FormFieldTypeEnum;
  config: GenericFieldConfig;
  page: number;
  visible: boolean;
};

export interface TextField extends GenericField {
  type: FormFieldTypeEnum.text;
  value: string;
  visible: boolean;
  config: TextFieldConfig;
}

export interface TextFieldConfig extends GenericFieldConfig {
  mask: boolean;
  excludeFromMetadata: boolean;
  showHelp: boolean;
  helpText: string;
  maxLength: number;
  minLength: number;
}

export interface TextAreaField extends GenericField {
  type: FormFieldTypeEnum.textarea;
  value: string;
  visible: boolean;
  config: TextAreaFieldConfig;
}

export interface TextAreaFieldConfig extends GenericFieldConfig {
  minLength: number;
  maxLength: number;
}

export interface NumberField extends GenericField {
  type: FormFieldTypeEnum.number;
  value: string; //much easier to deal with empty string than "empty numbers". then only convert to number when comparing
  visible: boolean;
  config: NumberFieldConfig;
}

export interface NumberFieldConfig extends GenericFieldConfig {
  minValue: number;
  maxValue: number;
  mask: boolean;
}

export interface CheckboxField extends GenericField {
  type: FormFieldTypeEnum.checkbox;
  value: boolean;
  visible: boolean;
  config: CheckboxFieldConfig;
}

export interface CheckboxFieldConfig extends GenericFieldConfig {
  trueLabel: string;
  falseLabel: string;
  checked: boolean;
  buttonsList: boolean;
}

export interface ChecklistField extends GenericField {
  type: FormFieldTypeEnum.checklist;
  value: string;
  visible: boolean;
  config: ChecklistFieldConfig;
}

export interface ChecklistFieldConfig extends GenericFieldConfig {
  checked: boolean;
  multiSelect: boolean;
  labelPlacement: ChecklistFieldLabel;
  options: ChecklistOption[];
  vertical: boolean;
}

export type ChecklistOption = {
  id: string;
  isChecked: boolean;
  label: string;
  show: boolean;
  value: string;
};

export enum ChecklistFieldLabel {
  bottom = 'bottom',
  end = 'end',
  start = 'start',
  top = 'top',
}

export interface RadiogroupField extends GenericField {
  type: FormFieldTypeEnum.radiogroup;
  value: string;
  visible: boolean;
  config: RadiogroupFieldConfig;
}

export interface RadiogroupFieldConfig extends GenericFieldConfig {
  checked: boolean;
  multiSelect: boolean;
  labelPlacement: RadiogroupFieldLabel;
  options: RadiogroupOption[];
  vertical: boolean;
}

export type RadiogroupOption = {
  id: string;
  isChecked: boolean;
  label: string;
  show: boolean;
  value: string;
};

export enum RadiogroupFieldLabel {
  bottom = 'bottom',
  end = 'end',
  start = 'start',
  top = 'top',
}

// Button List field types
export interface ButtonListField extends GenericField {
  type: FormFieldTypeEnum.buttonlist;
  value: string;
  visible: boolean;
  config: ButtonListFieldConfig;
}

export interface ButtonListFieldConfig extends GenericFieldConfig {
  multiSelect: boolean;
  labelPlacement: ButtonListFieldLabel;
  options: ButtonListOption[];
  vertical: boolean;
}

export type ButtonListOption = {
  id: string;
  isChecked: boolean;
  label: string;
  show: boolean;
  value: string;
};

export enum ButtonListFieldLabel {
  bottom = 'bottom',
  end = 'end',
  start = 'start',
  top = 'top',
}

export interface PasswordField extends GenericField {
  type: FormFieldTypeEnum.password;
  value: string;
  visible: boolean;
  config: PasswordFieldConfig;
}

export interface PasswordFieldConfig extends GenericFieldConfig {
  minLength: number;
  maxLength: number;
}

export interface DropDownField extends GenericField {
  type: FormFieldTypeEnum.dropdown;
  value: string[];
  visible: boolean;
  config: DropDownFieldConfig;
}

export interface DropDownFieldConfig extends GenericFieldConfig {
  multiSelect: boolean;
  buttonsList: boolean;
  options: DropDownOption[];
}

export type DropDownOption = {
  id: string;
  label: string;
  value: string;
  show: boolean;
  isDefault: boolean;
};

export interface DateTimeField extends GenericField {
  type: FormFieldTypeEnum.datetime;
  value: string;
  visible: boolean;
  config: DateTimeFieldConfig;
}

export interface DateTimeFieldConfig extends GenericFieldConfig {
  minValue: Date | null;
  maxValue: Date | null;
  returnFormat: string;
  defaultToNow: boolean;
}

export interface HiddenField extends GenericField {
  id: string;
  visible: boolean;
  value: string;
  type: FormFieldTypeEnum.hidden;
  config: GenericFieldConfig;
}

export interface LabelField extends GenericField {
  id: string;
  type: FormFieldTypeEnum.label;
  visible: boolean;
  config: GenericFieldConfig;
}

export interface PageBreakField extends GenericField {
  id: string;
  visible: boolean;
  type: FormFieldTypeEnum.pageBreak;
  config: PageBreakFieldConfig;
}

export interface PageBreakFieldConfig extends GenericFieldConfig {
  hideBack: boolean;
  hideNext: boolean;
  hideScan: boolean;
  hideHome: boolean;
  hideReturn: boolean;
  hidePageNumbers: boolean;
}

//TODO: improve field value type to account for all info needed
export interface FileField extends GenericField {
  type: FormFieldTypeEnum.file;
  config: FileFieldConfig;
  width: number;
  height: number;
  value: FileFieldValue[];
}

export interface FileFieldConfig extends GenericFieldConfig {
  variable: string;
  fileTypes: string;
  maxFileSize: number;
  maxFileSizeUnits: string;
  allowMultipleFiles: boolean;
  title: string;
  required: boolean;
}

export type FileFieldValue =
  | {
    new: false;
    fileId: string;
  }
  | {
    new: true;
    file: File;
  };

export interface SignatureField extends GenericField {
  type: FormFieldTypeEnum.signature;
  config: SignatureFieldConfig;
  width: number;
  height: number;
  value: string;
  signed?: boolean;
}

export interface SignatureFieldConfig extends GenericFieldConfig {
  minLength: number;
  maxLength: number;
  drawSign: boolean;
  typeSign: boolean;
  uploadImageSign: boolean;
}

export interface SquareTool extends GenericField {
  type: FormFieldTypeEnum.square;
}

export interface CircleTool extends GenericField {
  type: FormFieldTypeEnum.circle;
}
export interface TriangleTool extends GenericField {
  type: FormFieldTypeEnum.triangle;
}
export interface StarTool extends GenericField {
  type: FormFieldTypeEnum.star;
}
export interface AsteriskTool extends GenericField {
  type: FormFieldTypeEnum.asterisk;
}
export interface DiamondTool extends GenericField {
  type: FormFieldTypeEnum.diamond;
}
export interface PlusTool extends GenericField {
  type: FormFieldTypeEnum.plus;
}
export interface MinusTool extends GenericField {
  type: FormFieldTypeEnum.minus;
}
export interface LineTool extends GenericField {
  type: FormFieldTypeEnum.line;
}
export interface TextTool extends GenericField {
  type: FormFieldTypeEnum.texttool;
}
export interface ImageTool extends GenericField {
  type: FormFieldTypeEnum.imagetool;
  config: ImageToolConfig;
}
export interface ImageToolConfig extends GenericFieldConfig {
  opacity: number;
}

export type FormField =
  | TextField
  | TextAreaField
  | NumberField
  | PasswordField
  | CheckboxField
  | ChecklistField
  | RadiogroupField
  | ButtonListField
  | DropDownField
  | DateTimeField
  | HiddenField
  | LabelField
  | PageBreakField
  | FileField
  | SignatureField
  | SquareTool
  | CircleTool
  | TriangleTool
  | StarTool
  | AsteriskTool
  | DiamondTool
  | PlusTool
  | MinusTool
  | LineTool
  | TextTool
  | ImageTool;
