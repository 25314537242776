import { Grid, useTheme } from '@material-ui/core';
import { ThreeDots } from 'react-loader-spinner';

function Loading() {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{
        backgroundColor: 'white',
        padding: '0 20% 0 20%',
      }}
      justifyContent="center"
      alignContent="center"
      xs
      direction="column"
    >
      <Grid item className="mx-auto">
        <ThreeDots color={theme.palette.primary.main} width="8em" />
      </Grid>
    </Grid>
  );
}

export default Loading;
