import axios from '../../lib/axios';
import { useMemo } from 'react';
import { useAppConfig } from '../context/AppState/AppContext';
import { useAppArgs } from './useAppArgs';
import jwt from 'jsonwebtoken';
import i18 from 'i18next';
import { useAuthContext } from '../context/AuthState/AuthContext';

export const useMetadataApi = () => {
  const { state: appState } = useAppConfig();
  const { state: authState } = useAuthContext();
  const { fallbackDomain, tenant } = useAppArgs();
  const { workflowId } = appState;

  const metadataApi = useMemo(() => {
    async function getProcessMetadata(processId: string) {
      const response = await axios.get(
        `https://${tenant.region ?? 'us-east-1'
        }.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${processId}`,
      );
      return response.data;
    }

    async function uploadProcessMetadata(processId: string, payload: any) {
      const response = await axios.put(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${processId}`,
        payload,
      );
      return response.data;
    }

    async function getFileMetadata(fileId: string) {
      const response = await axios.get(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/file/${appState.processId}/${fileId}`,
      );
      return response.data;
    }

    async function uploadFileMetadata(fileId: string, payload: any, processId?: string) {
      const response = await axios.put(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/file/${processId ?? appState.processId}/${fileId}`,
        payload,
      );
      return response.data;
    }

    async function uploadFileToProcessMetadata(fileId: string, processId?: string) {
      const response = await axios.patch(
        `https://${tenant.region}.metadata-api.${fallbackDomain}/api/v1/process/metadata/${processId ?? appState.processId}`, {
        commands: [
          {
            op: 'add',
            path: 'files',
            value: fileId.replace(/'/g, "''")
          }
        ]
      }
      );
      return response.data;

    }

    async function uploadFormToProcessMetadata(
      processId: string,
      formId: string,
      payload: any,
      extraData: any = {},
    ) {
      try {
        const processData = await getProcessMetadata(processId);
        if (!processData.data) {
          throw new Error('Unknown response');
        }
        const updatedProcessData = {
          ...processData,
        };

        //form metadata
        normalizeFormMetadata(updatedProcessData, payload, formId);

        //extra data like starting node metadata
        updatedProcessData.data = {
          ...updatedProcessData.data,
          ...extraData,
        };

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        //form submissions
        normalizeFormSubmissionMetadata(updatedProcessData, {
          formId,
          submittedBy: user.sub,
          submittedAt: Date.now(),
        }, formId);
        
        const response = await uploadProcessMetadata(
          processId,
          updatedProcessData,
        );
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    async function uploadFormSubmissionToProcessMetadata(
      processId: string,
      formId: string,
    ) {
      try {
        const processData = await getProcessMetadata(processId);
        if (!processData.data) {
          throw new Error('Unknown response');
        }
        const updatedProcessData = {
          ...processData,
        };

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        //form submissions
        normalizeFormSubmissionMetadata(updatedProcessData, {
          formId,
          submittedBy: user.sub,
          submittedAt: Date.now(),
        }, formId);

        const response = await uploadProcessMetadata(
          processId,
          updatedProcessData,
        );
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    async function uploadFormSubmissionToFileMetadata(
      fileId: string,
      formId: string,
    ) {
      try {
        const processData = await getFileMetadata(fileId);
        if (!processData.data) {
          throw new Error('Unknown response');
        }
        const updatedProcessData = {
          ...processData,
        };

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        //form submissions
        normalizeFormSubmissionMetadata(updatedProcessData, {
              formId,
              submittedBy: user.sub,
              submittedAt: Date.now(),
            }, formId);

        const response = await uploadFileMetadata(fileId, updatedProcessData);
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    async function uploadFormToFileMetadata(
      fileId: string,
      formId: string,
      payload: any,
    ) {
      try {
        const fileData = await getFileMetadata(fileId);
        if (!fileData.data) {
          throw new Error('Unknown response');
        }
        const updatedFileMetadata = {
          ...fileData,
        };

        //form metadata
        normalizeFormMetadata(updatedFileMetadata, payload, formId);

        //parse user token to extract userId
        const user = jwt.decode(authState.token!) as {
          sub: string;
        } | null;
        if (!user) {
          return { error: i18.t('Failed to parse user token') };
        }

        const response = await uploadFileMetadata(fileId, updatedFileMetadata);
        return response.data;
      } catch (err) {
        console.error(err);
        return { error: i18.t('Failed to upload form metadata') };
      }
    }

    function normalizeFormMetadata(
      metaData: any,
      formPayload: any,
      formId: any,
    ) {
      const formData = { ...formPayload, ...{ ".id": formId, ".type": "form" } };

      if (!metaData.data)
        metaData.data = {};

      //form metadata
      if (metaData.data.form) {
        if (Array.isArray(metaData.data.form)) {
          const index = metaData.data.form.findIndex((f: { ".id": any; }) => f[".id"] === formData[".id"]);
          if (index >= 0)
            metaData.data.form[index] = { ...metaData.data.form[index], ...formData };
          else
            metaData.data.form.push({ ...formData });
        } else if (metaData.data.form[".id"] === formData[".id"]) {
          metaData.data.form = { ...formData };
        } else {
          metaData.data.form = [metaData.data.form, { ...formData }];
        }
      } else {
        metaData.data.form = { ...formData };
      }
    }

    function normalizeFormSubmissionMetadata(
      metaData: any,
      formSubmissionPayload: any,
      formId: any,
    ) {
      const formSubmissionData = { ...formSubmissionPayload, ...{ formId, ".type": "formsubmission" } };

      if (!metaData.data)
        metaData.data = {};

      //form submission metadata
      if (metaData.data.formsubmission) {
        if (Array.isArray(metaData.data.formsubmission)) {
          const index = metaData.data.formsubmission.findIndex((f: { formId: any; }) => f.formId === formSubmissionData.formId);
          if (index >= 0)
            metaData.data.formsubmission[index] = { ...formSubmissionData };
          else
            metaData.data.formsubmission.push({ ...formSubmissionData });
        } else if (metaData.data.formsubmission.formId === formSubmissionData.formId) {
          metaData.data.formsubmission = { ...formSubmissionData };
        } else {
          metaData.data.formsubmission = [metaData.data.formsubmission, { ...formSubmissionData }];
        }
      } else {
        metaData.data.formsubmission = { ...formSubmissionData };
      }
    }

    function getFormMetadata(
      metaData: any,
      formId: any,
    ) {
      if (!(metaData && metaData.data && formId))
        return null;

      //look for form in the form metadata
      if (metaData.data.form) {
        if (Array.isArray(metaData.data.form))
          return metaData.data.form.find((f: { ".id": any; }) => f[".id"] === formId);
        else if (metaData.data.form[".id"] === formId)
          return metaData.data.form;
      }

      //backward compatibility with earlier forms metadata structure
      if (metaData.data.forms && metaData.data.forms[formId]) {
        console.log('using the Form metadata from earlier Form structure for backward compatibility');
        return metaData.data.forms[formId];
      }

      return null;
    }

    return {
      getProcessMetadata,
      uploadProcessMetadata,
      getFileMetadata,
      uploadFileMetadata,
      uploadFormToProcessMetadata,
      uploadFormToFileMetadata,
      uploadFormSubmissionToProcessMetadata,
      uploadFormSubmissionToFileMetadata,
      uploadFileToProcessMetadata,
      normalizeFormMetadata,
      normalizeFormSubmissionMetadata,
      getFormMetadata
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, fallbackDomain, workflowId]);
  return metadataApi;
};
