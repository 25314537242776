import Layout from './mfp/layout';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getForm, getFormUrlData, authWithFormsAppClient } from '../lib/formApi';
import { useAppConfig } from './context/AppState/AppContext';
import { useFormUtils } from './hooks/useFormUtils';
import { trackPromise } from 'react-promise-tracker';
import { AxiosError } from 'axios';
import { MessageBoxContext, MessageBoxStateActions, MessageBoxType } from '@dispatcher-stratus/stratus-react';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { useAppArgs } from './hooks/useAppArgs';
import { useAuthContext, useAuthApiContext } from './context/AuthState/AuthContext';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useNTFApi } from './hooks/useNTFApi';
import { getTenantByPoolId, getWorkflow } from '../lib/wfxApi';
import { useFormApi } from './hooks/useFormApi';
import Loading from './Loading';
import Altcha from './Altcha';
import { getChallengeUrl, getVerifyUrl } from '../lib/altchaApi';

type Props = {
  children: React.ReactNode;
};
/**
 * Extracts the requireRecaptcha value from the nodes array of the given workflow object,
 * matching the id with the provided targetId.
 *
 * @param {any} data - The workflow object containing nodes array.
 * @param {string} targetId - The target node id to match.
 * @returns {boolean|null} - The requireRecaptcha value if found, otherwise null.
 */
const extractRequireRecaptcha = (data: any, targetId: string): boolean | null => {
  console.log('extractRequireRecaptcha targetId', targetId);
  console.log('extractRequireRecaptcha data', data);
  for (const node of data.nodes) {
    if (node.id === targetId) {
      return node.config.requireRecaptcha;
    }
  }
  return null;
};

export const JobTicketForm = (props: Props) => {
  const { state: auth } = useAuthContext();
  const { handleAuthSWS, setToken } = useAuthApiContext();
  const { setAppConfig } = useAppConfig();
  const { state: formState, dispatch: formDispatch } = useContext(FormDataContext);
  const { submitInternalForm } = useFormApi();
  const { urlId, tenant, fallbackDomain, domain } = useAppArgs();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const { massageFormInput } = useFormUtils();
  const { getUserPool } = useNTFApi();
  const { t } = useTranslation();
  const history = useHistory();

  //MARK: QUERIES

  const { data: userPoolData } = useQuery(
    ['userPool', [tenant.slug]],
    async () => {
      const userPool = await trackPromise(getUserPool());
      return userPool;
    },
    {
      enabled: !!fallbackDomain,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch user pool. Form url may be invalid`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['tenantData', tenant.slug],
    async () => {
      const { id, name, plan, timeZone } = await trackPromise(getTenantByPoolId(tenant.region, userPoolData.poolId));
      const tenantdata = {
        id,
        name,
        slug: tenant.slug,
        region: tenant.region,
        plan,
        timezone: timeZone,
      };
      setAppConfig({ tenant: tenantdata });
    },
    {
      enabled: !!userPoolData,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch tenant data.',
          },
        });
      },
    },
  );

  const { data: formUrlData, isLoading } = useQuery(
    ['formUrlData', urlId],
    async () => {
      const formUrlData = await trackPromise(getFormUrlData(urlId, tenant.region, fallbackDomain));

      if (formUrlData && 'anonymous' === formUrlData.formAuth) {
        const authResponse = await trackPromise(authWithFormsAppClient(tenant.slug, domain));
        if (authResponse && authResponse.access_token) {
          setToken(authResponse.access_token);
        } else {
          dispatchMessage({
            type: MessageBoxStateActions.MESSAGE_BOX,
            payload: {
              open: true,
              boxType: MessageBoxType.Ok,
              title: t('Authentication error'),
              message: t(
                'This tenant is not configured to support anonymous authentication. Contact your Dispatcher Stratus administrator.',
              ),
            },
          });
        }
      }

      if (!formUrlData.formId) {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Node configuration not saved. No form present.',
          },
        });
      } else {
        setAppConfig({
          formId: formUrlData.formId,
          nodeId: formUrlData.nodeId,
          workflowId: formUrlData.workflowId,
          startUrl: formUrlData.url,
        });
      }
      console.log('formUrlData: ', formUrlData);
      return formUrlData;
    },
    {
      enabled: !!urlId,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch form url data.',
          },
        });
      },
    },
  );

  const { data: workflow } = useQuery(
    ['checkWorkflowStatus'],
    async () => {
      if (!formUrlData || !formUrlData.workflowId) {
        console.log('workflow id missing');
        history.push('/unavailable');
        return null;
      }

      const workflow = await trackPromise(getWorkflow(tenant.region, auth.token!, formUrlData.workflowId));
      if (workflow?.status !== 'running') {
        console.log('workflow not running or no access');
        history.push('/unavailable');
        return null;
      }
      return workflow;
    },
    {
      enabled: !!formUrlData?.workflowId && !!auth.token,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('Error checking workflow status'),
            message: 'Failed to check workflow status.',
          },
        });
      },
    },
  );

  useQuery(
    ['formData', formUrlData?.formId],
    async () => {
      const formData = await trackPromise(
        getForm(tenant.region, tenant.slug, fallbackDomain, formUrlData?.formId!, auth.token!, formUrlData?.tenantId),
      );
      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }
      const massagedFormData = await massageFormInput(formData, {
        environment: new Map(),
        records: new Map(),
      });

      massagedFormData.originalFormDef = formData;
      formDispatch({
        type: FormActionType.POPULATE_FORM,
        payload: massagedFormData,
      });
      console.log('formData: ', formData);
      console.log('massagedFormData: ', massagedFormData);
      return massagedFormData;
    },
    {
      enabled: !!formUrlData?.formId && !!auth.isAuthenticated,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  //MARK: EFFECTS

  useEffect(() => {
    if (!auth.isAuthenticated && formUrlData && 'anonymous' !== formUrlData.formAuth) {
      const success = handleAuthSWS();
      if (!success) {
        history.push(`/unauthrized`);
        return;
      }
    }
  }, [history, auth.isAuthenticated, handleAuthSWS, formUrlData]);

  if (isLoading) {
    return <Loading />;
  }

  // Extract requireRecaptcha from the workflow object using formUrlData.nodeId
  console.log('workflow', workflow);
  const requireRecaptcha =
    workflow && formUrlData && formUrlData.nodeId ? extractRequireRecaptcha(workflow, formUrlData.nodeId) : null;
  const captchaRequired = formUrlData?.formAuth === 'anonymous' && requireRecaptcha;

  return (
    <Layout hideHeader hideActionBar onSubmit={async () => {}}>
      <div className="flex flex-col flex-nowrap w-full">
        <div>{props.children}</div>
        {formState.loaded && formState.currentPage.number === formState.numPages && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await trackPromise(submitInternalForm(formState));
              return false;
            }}
          >
            <div className="flex flex-row justify-center">
              <div className="flex flex-col justify-center my-2">
                <div className="flex my-1">
                  {captchaRequired && <Altcha challengeurl={getChallengeUrl} verifyurl={getVerifyUrl} hidelogo />}
                </div>
                <div className="flex flex-row">
                  <Button type="submit" disabled={!formState.valid} variant="contained" color="primary">
                    {t('Submit')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};
