import { memo } from 'react';
import Spinner from '../Spinner';
import { Body } from '../mfp/body';
// import Header from '../embed/Header';
// import { AppFooter } from '../footer/app-footer';

export declare interface Props {
  children?: React.ReactNode; // best, accepts everything React can render
  next?: string;
  back?: string;
  onSubmit: () => void;
}

const Layout = (props: Props): JSX.Element => {
  return (
    <>
      <div className="bg-white h-screen flex flex-col ">
        <Spinner />
        {/* <Header /> */}
        <Body className="justify-center overflow-x-auto h-full overflow-y-auto">
          {props.children}
        </Body>
        {/* <AppFooter /> */}
      </div>
    </>
  );
};

export default memo(Layout);
