import { useContext } from 'react';
import { SnackbarContext } from '../context/Snackbar';

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error(
      'useSnackbar has to be used within <SnackbarContext.Provider>',
    );
  }

  return context;
};
