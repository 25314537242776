import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getSsoUserToken, getToken } from '../lib/cognitoApi';
import { trackPromise } from 'react-promise-tracker';
import Loading from './Loading';
import { useAuthApiContext } from './context/AuthState/AuthContext';
import { useAppArgs } from './hooks/useAppArgs';

export default function Login() {
  const location = useLocation();
  const history = useHistory();
  const { domain } = useAppArgs();
  const search = new URLSearchParams(location.search);
  const code = search.get('code') || '';
  const stateExists = search.get('state');

  if (!stateExists) {
    history.goBack();
  }

  const state = JSON.parse(atob(search.get('state') || ''));
  const { setToken, setTokenError } = useAuthApiContext();
  const { path, slug, client_id, clientSecret, authMethod } = state;

  const params = {
    grant_type: 'authorization_code',
    client_id,
    code: code,
    redirect_uri: `http${
      window.location.hostname === 'localhost' ? '' : 's'
    }://${window.location.host}/sso/login`,
  };

  const { isLoading } = useQuery(
    ['tokenQuery', params],
    async () => {
      if (authMethod === 'sso-stratus-user') {
        return await trackPromise(
          getSsoUserToken(
            slug, state.region, client_id, params, domain
          )
        )
      } else {
        return await trackPromise(
            getToken(slug, state.region, client_id, params, clientSecret),
          )
      }
      
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (token) => {
        setToken(token);
        if (window.opener) {
          window.opener.postMessage({
            source: 'forms-app-login',
            payload: { success: true, token: token },
          });
        } else {
          history.push(path);
        }
      },
      onError: (err) => {
        console.error(err);
        setTokenError();
        history.push('/unavailable')
      },
    },
  );

  console.log('HISTORY: ', history)
  console.log('here is loading', isLoading)
  return <>{isLoading ? <Loading /> : 'Redirecting'}</>;
}
