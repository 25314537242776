import { memo } from 'react';
import { PageBreakField as PageBreakFieldType } from '../../interfaces/FormFieldTypes';
import { Typography, makeStyles } from '@material-ui/core';

type Props = {
  field: PageBreakFieldType;
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '2em',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0.2em',
    paddingLeft: '0.5em',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    width: '100%',
  },
}));

const PageBreakField = ({ field }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h6">{field.config.helpText}</Typography>
    </div>
  );
};

export default memo(PageBreakField);
