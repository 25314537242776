import { FormBaseDataType } from '../../interfaces';
import axios from '../../lib/axios';
import { trackPromise } from 'react-promise-tracker';

export const usePersistForm = () => {
  const getPersistedForm = async (
    workflowId: string,
    processId: string,
    formId: string,
    region: string,
    domain: string,
    token: string,
    tenantSlug: string,
    tenantId?: string,
  ) => {
    const processMetadata = await trackPromise(
      axios.get(
        `https://${region ?? 'us-east-1'}.metadata-api.${domain}/api/v1/process/${workflowId}/${processId}`,
      ),
    ).catch((err) => {
      console.error('Error fetching process metadata:', err);
    });

    if (processMetadata && processMetadata.data && processMetadata.data.data && processMetadata.data.data.formlayout && processMetadata.data.data.formlayout && processMetadata.data.data.formlayout.length) {
      const persistedForm = processMetadata.data.data.formlayout.find((item: FormBaseDataType) => item.id === formId);
      if (persistedForm) {
        console.log('getPersistedForm: returning persisted Form from metadata ...');
        return persistedForm;
      }
    }

    const response = await axios
      .get(`https://${region ?? 'us-east-1'}.forms-service.${domain}/api/forms/${formId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'stc-tenant-slug': tenantSlug,
          'x-tenant-id': tenantId || '',
        },
        withCredentials: false,
      })
      .catch((err) => {
        console.error(err);
        throw new Error('error getPersistedForm');
      });
    if (response.data.code) {
      console.error(response.data);
      throw new Error('error getPersistedForm');
    }
    console.log('getPersistedForm: returning active Form from Forms Service ...');
    return response.data;
  };

  const persistFormDefinition = async (
    workflowId: string,
    processId: string,
    formDefinition: FormBaseDataType,
    region: string,
    domain: string,
  ) => {

    const response = await axios.get(
      `https://${region ?? 'us-east-1'}.metadata-api.${domain}/api/v1/process/${workflowId}/${processId}`,
    );
    
    const processMetadataPayload = response.data ?? {};
    if (!processMetadataPayload.data)
      processMetadataPayload.data = {};

    if (!processMetadataPayload.data.formlayout)
      processMetadataPayload.data.formlayout = [];

    processMetadataPayload.data.formlayout.push({
      ...formDefinition,
      ...{ '.type': 'formlayout' }
    });

    const updateMetadataResponse = await trackPromise(
      axios.put(
        `https://${region ?? 'us-east-1'}.metadata-api.${domain}/api/v1/process/${workflowId}/${processId}`,
        processMetadataPayload,
      ),
    ).catch((err) => {
      console.error('Error persisting form definition:', err);
      throw new Error('error persistFormDefinition');
    });
    return updateMetadataResponse.data;
  };

  return {
    getPersistedForm,
    persistFormDefinition,
  };
};
