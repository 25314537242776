import { useFormData } from '../context/FormState/FormDataContext';
import { DateTimeField as DateTimeFieldType } from '../../interfaces/FormFieldTypes';
import 'react-datepicker/dist/react-datepicker.css';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField, Grid, makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { useEffect, useState, memo } from 'react';
import { t } from 'i18next';
import { isAfter, isBefore, isValid, min, max } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useFormUtils } from '../hooks/useFormUtils';
import { useAppConfig } from '../context/AppState/AppContext';
import iconDate from '../../assets/icon-form-field-datetime.svg';
type Props = {
  field: DateTimeFieldType;
};

const useStyles = makeStyles({
  root: (props: Props) => ({
    '& fieldset': {
      borderWidth: props.field.config.styling?.borderWidth
        ? `${props.field.config.styling?.borderWidth}px !important`
        : undefined,
      borderRadius: props.field.config.styling?.borderRadius
        ? `${props.field.config.styling?.borderRadius}px`
        : undefined,
      borderColor: props.field.config.styling?.borderColor
        ? `${props.field.config.styling?.borderColor} !important`
        : undefined,
      borderStyle: props.field.config.styling?.borderStyle,
      '& legend': {
        ...props.field.config.styling?.labelStyling,
      },
    },
  }),
});

const DateTimeField = memo(({ field }: Props) => {
  const { labelPosition, labelStyling, borderWidth, borderRadius, borderColor, borderStyle, ...rootStyling } =
    field.config.styling ?? {};

  const classes = useStyles({ field });
  const { state, dispatch } = useFormData();
  const { formatDate, parseDate, adjustDateTime } = useFormUtils();
  const { state: appState } = useAppConfig();
  const fieldError = state.errors.get(field.id);
  const [controlValue, setControlValue] = useState(field.value);

  useEffect(() => {
    if (field.config.readonly) return;
    console.log('date value: ', field.value);
    setControlValue(field.value);
    function validateField(): string {
      const { required } = field.config;
      if (!field.value) {
        if (required) return t('error empty');
      } else {
        const date = parseDate(field.value);
        if (!isValid(date)) return t('error dateInvalid');
        if (field.config.maxValue && isAfter(date, field.config.maxValue))
          return t('error dateMax', {
            max: formatDate(field.config.maxValue),
          });
        if (field.config.minValue && isBefore(date, field.config.minValue))
          return t('error dateMin', {
            min: formatDate(field.config.minValue),
          });
      }
      return '';
    }
    const error = validateField();
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: error,
      },
    });
  }, [field, dispatch, parseDate, formatDate]);

  const onDateSelect = (date: Date) => {
    if (isValid(date))
      dispatch({
        type: FormActionType.SET_FIELD_VALUE,
        payload: {
          id: field.id,
          value: formatDate(date),
        },
      });
    setControlValue(formatDate(adjustDateTime(appState.timeOffset, date)));
  };

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setControlValue(e.target.value);
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.value,
      },
    });
  };
  const labelPositionStyling = {
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: -30,
      top: '50%',
      transform: 'translate(calc(100% + 12px), -50%)',
    } as const,
    top: {
      top: 0,
      left: 0,
      transform: 'translate(0, -100%)',
    } as const,
    bottom: {
      bottom: -5,
      left: 14,
      transform: 'translate(0, 100%)',
    } as const,
    'inside-top': {
      top: 5,
      left: 14,
    } as const,
    'within-top': {} as const, // just needed for TS
  }[labelPosition ?? 'within-top'];

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <div className="flex relative">
          <TextField
            size="small"
            variant="outlined"
            className={`w-full ${classes.root}`}
            value={controlValue}
            onChange={onDateChange}
            error={!!fieldError}
            disabled={field.config.readonly}
            label={['within-top', undefined].includes(labelPosition) ? field.config.title : undefined}
            helperText={fieldError}
            InputLabelProps={{
              shrink: true,
              style: {
                top: 0,
                left: 14,
                transform: 'translate(0, calc(-50% + 2px)) scale(0.75)',
                lineHeight: 1,
                ...labelStyling,
                fontSize: labelStyling?.fontSize ? labelStyling?.fontSize / 0.75 : undefined,
                marginLeft: borderWidth,
              },
            }}
            InputProps={{
              endAdornment: !field.config.readonly ? (
                <InputAdornment position="end">
                  <DatePicker
                    // Goal: always keep datepicker within range and use current date if within range
                    // use field.value if value is valid
                    // if field.value not valid use current date
                    // if no field.value take min(maxValue, max(minValue, new Date()))
                    // if minValue or maxValue does not exist fallback to current Date when taking min/max
                    selected={
                      field.value
                        ? isValid(parseDate(field.value))
                          ? parseDate(field.value)
                          : adjustDateTime(appState.timeOffset)
                        : field.config.maxValue
                        ? min([
                            adjustDateTime(appState.timeOffset, new Date(field.config.maxValue)),
                            field.config.minValue
                              ? max([
                                  adjustDateTime(appState.timeOffset, new Date(field.config.minValue)),
                                  adjustDateTime(appState.timeOffset),
                                ])
                              : adjustDateTime(appState.timeOffset),
                          ])
                        : adjustDateTime(appState.timeOffset)
                    }
                    onChange={onDateSelect}
                    customInput={
                      <IconButton className="p-0">
                        <img className="w-6 h-6" src={iconDate} alt="iconDate" />
                      </IconButton>
                    }
                    popperPlacement="bottom-end"
                    portalId="root-portal"
                    minDate={field.config.minValue}
                    maxDate={field.config.maxValue}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </InputAdornment>
              ) : undefined,
              style: {
                ...rootStyling,
                borderRadius,
                overflowY: 'hidden',
                overflowX: 'hidden',
                // paddingRight: 20,
              },
            }}
            inputProps={{
              style: {
                textAlign: field.config?.styling?.textAlign,
                textDecorationLine: field.config?.styling?.textDecorationLine,
                margin: borderWidth,
              },
            }}
          />
          {!['within-top', undefined].includes(labelPosition) && (
            <InputLabel
              style={{
                position: 'absolute',
                maxWidth: 'none',
                fontSize: '0.75rem',
                transition: 'none',
                whiteSpace: 'nowrap',
                ...labelPositionStyling,
                ...labelStyling,
                width: 'unset',
                paddingRight: 'unset',
              }}
            >
              {`${field.config.title} ${field.config.required ? '*' : ''}`}
            </InputLabel>
          )}
          {!field.config.readonly && <div className="absolute right-2 top-1"></div>}
        </div>
      </Grid>
    </Grid>
  );
});

export default DateTimeField;
