import { Grid, Typography } from '@material-ui/core';
import Layout from './layout';
import UnavailableIcon from '../../assets/unavailable.svg';
import { useTranslation } from 'react-i18next';

function Unavailable() {
  const { t } = useTranslation();
  document.title = 'Form Unavailable - Dispatcher Stratus';

  return (
    <Layout onSubmit={() => { }}>
      <Grid
        container
        direction="column"
        item
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item className="m-2">
          <img src={UnavailableIcon} alt="" />
        </Grid>
        <Grid item className="m-2">
          <Typography variant="h5" align="center" className="font-bold">
            {t('form unavailable header 1')}
          </Typography>
          <Typography variant="h5" align="center" className="font-bold">
            {t('form unavailable header 2')}
          </Typography>
        </Grid>
        <Grid item className="m-2">
          <Typography variant="body1">{t('form unavailable body')}</Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Unavailable;
