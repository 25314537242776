import React, { ReactElement, ReactNode } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { GenericFieldStyling } from '../interfaces/FormFieldTypes';

interface Props {
  id: string;
  label?: string;
  styling?: Partial<GenericFieldStyling>;
  children: ReactNode;
  className?: string;
  contentWrapperClass?: string;
  contentClass?: string;
  onClick?: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { styling?: Partial<GenericFieldStyling> }) => ({
    position: 'relative',
    marginTop: '8px',
    '& fieldset': {
      borderWidth: props.styling?.borderWidth ? `${props.styling?.borderWidth}px !important` : undefined,
      borderRadius: props.styling?.borderRadius ? `${props.styling?.borderRadius}px` : undefined,
      borderColor: props.styling?.borderColor ? `${props.styling?.borderColor} !important` : undefined,
      borderStyle: props.styling?.borderStyle,
      '& legend': {
        ...props.styling?.labelStyling,
      },
    },
  }),
  contentWrapper: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    // padding: '18.5px 14px',
    flexGrow: 1,
  },
  inputLabel: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 'auto',
    paddingRight: '0',
    // slight alteration to spec spacing to match visual spec result
    // transform: 'translate(0, 24px) scale(1)',
    transform: 'translate(0, calc(-50% + 2px)) scale(0.75)',
  },
  notchedOutline: {
    borderRadius: theme.shape.borderRadius,
    // borderColor: '#9ca3af'
  },
}));

const LabeledOutline = ({
  id,
  label,
  styling,
  children,
  className,
  contentWrapperClass,
  contentClass,
  onClick = () => {},
}: Props): ReactElement => {
  const { labelPosition, labelStyling, borderWidth, borderRadius, borderColor, borderStyle, ...rootStyling } =
    styling ?? {};

  const classes = useStyles({ styling });
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);
  React.useEffect(() => {
    if (labelRef && labelRef.current && (labelRef.current as any).offsetWidth) {
      setLabelWidth((labelRef.current as any).offsetWidth);
    }
  }, [label]);
  return (
    <div
      className={clsx(className, classes.root)}
      style={{
        ...rootStyling,
        borderRadius,
        // FIXME::adding these cuts off the label
        // overflowY: 'hidden',
        // overflowX: 'hidden',
      }}
    >
      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant="outlined"
        className={classes.inputLabel}
        shrink
        style={{
          ...labelStyling,
          fontSize: labelStyling?.fontSize ? labelStyling?.fontSize / 0.75 : undefined,
          marginLeft: borderWidth,
        }}
      >
        {label}
      </InputLabel>
      <div className={`${classes.contentWrapper} ${contentWrapperClass}`} onClick={(e) => onClick(e)}>
        <div id={id} className={`${classes.content} ${contentClass}`}>
          <div
            className="content-center"
            style={{
              // FIXME::this margin behaves funny at small sizes so for now we're just applying a marginLeft
              // margin: borderWidth,
              marginLeft: borderWidth,
              height: '100%',
            }}
          >
            {children}
          </div>
          <NotchedOutline className={`border-gray-400 ${classes.notchedOutline}`} notched labelWidth={labelWidth} />
        </div>
      </div>
    </div>
  );
};

export default LabeledOutline;
