import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormData } from '../context/FormState/FormDataContext';
import { trackPromise } from 'react-promise-tracker';

function SaveButton(props: { onClick: () => Promise<void> }) {
  const { t } = useTranslation();
  const { state: formState } = useFormData();

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={!!!formState.valid}
      onClick={async () => {
        await trackPromise(props.onClick());
      }}
    >
      {t('Save')}
    </Button>
  );
}

export default SaveButton;
