import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSnackbarApi } from '../hooks/useSnackbarApi';

function ResetButton() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showSuccessMessage, hideMessage } = useSnackbarApi();

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={async () => {
        hideMessage();
        queryClient.invalidateQueries('processData');
        queryClient.invalidateQueries('formData');
        showSuccessMessage({ text: t('Form Reset.') });
      }}
    >
      {t('Reset')}
    </Button>
  );
}

export default ResetButton;
