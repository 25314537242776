import { AUTH_METHODS, REGION } from '../interfaces';
import axios from './axios';

export async function getFormUrlData(
  urlId: string,
  region: string,
  domain: string,
): Promise<{
  id: string;
  url: string;
  creator: string;
  editor: string;
  formId: string;
  nodeId: string;
  workflowId: string;
  tenantRegion: string;
  tenantSlug: string;
  tenantId?: string;
  updated: number;
  created: number;
  formAuth: AUTH_METHODS;
  requireRecaptcha?: boolean;
}> {
  try {
    const response = await axios.get(
      `https://${region}.forms-service.${domain}/api/formurl/${urlId}`,
      {
        headers: {
          Authorization: `Bearer 2cdd77cf-e359-4276-96e8-2c991582a1e2`,
        },
      },
    );
    /*
    TODO: remove the requireRecaptcha override - need to fix form-builder-service FormUrl.ts to return requireRecaptcha
    */
    response.data.requireRecaptcha = 'anonymous' === response.data.formAuth; //true;

    console.log('formurl data', response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error('Failed to fetch form url data');
  }
}

export async function getForm(
  region: string,
  slug: string,
  domain: string,
  formId: string,
  token: string,
  tenantId?: string,
) {

  try {
    if (!formId) throw Error('No Form ID');
    const response = await axios.get(
      `https://${
        region ?? 'us-east-1'
      }.forms-service.${domain}/api/forms/${formId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'stc-tenant-slug': slug,
          'x-tenant-id': tenantId || '',
        },
        withCredentials: false,
      },
    );
    console.log('form data response', response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error('failed to fetch form');
  }
}

export async function authWithFormsAppClient(slug: string, domain: string) {
  try {
    const response = await axios.post(
      `https://${slug}.tenant.${domain}/api/sso/forms/login`,
      {
        headers: {
          Authorization: 'Bearer f62fad1f-17ac-40c2-81b4-37b0f2de153e',
        },
        withCredentials: false,
      },
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error('failed to authenticate using form app client.');
  }
}

export async function authWithFormsSamlAppClient(region: REGION, domain: string, token: string) {
  try {
    const response = await axios.post(
      `https://${region}.web.${domain}/api/sso/forms/saml/login`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: false,
      },
    )
    const data = response.data;
    return data.isValid;
  } catch (error) {
    console.error(error);
    throw new Error('failed to authenticate using saml form app client.');
  }
}

export async function getFormsSamlAppClient(region: REGION, domain: string, slug: string) {
  try {
    const response = await axios.get(
      `https://${region}.web.${domain}/api/sso/v2/service/client/${slug}/saml`,
      {
        headers: {
          Authorization: 'Bearer f62fad1f-17ac-40c2-81b4-37b0f2de153e',
        },
        withCredentials: false,
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('failed to get form app client.');
  }
}

export async function getSamlProviderName(region: string, domain: string, type: 'sso-non-stratus-user' | 'sso-stratus-user', tenantId?: string) {
  try {
    const convertedType = type === 'sso-non-stratus-user' ? 'sso-non-user' : 'sso-user'
    const response = await axios.get(
      `https://${region}.web.${domain}/api/tenants/saml/${tenantId}/provider?type=${convertedType}`,
      {
        headers: {
          Authorization: 'Bearer f62fad1f-17ac-40c2-81b4-37b0f2de153e',
        },
        withCredentials: false,
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('failed to get form app client.');
  }
}