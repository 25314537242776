import { DOMAIN } from '../interfaces/AppState';

const domain: DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
const getUrl = (domain: DOMAIN): string => {
  if (domain === 'localhost') domain = 'stratus.lol';
  return `https://${domain}`;
};

// Assign the URLs to variables
export const getChallengeUrl = `${getUrl(domain)}/api/altcha/challenge`;
export const getVerifyUrl = `${getUrl(domain)}/api/altcha/verify`;