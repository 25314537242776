import { FC, memo, useState, useMemo, useEffect, useRef, RefObject } from 'react';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { SignatureField as SignatureFieldType } from '../../interfaces/FormFieldTypes';
import { Typography, makeStyles, Box, TextField, InputLabel, Link } from '@material-ui/core';
import { t } from 'i18next';
import ReactSignatureCanvas from 'react-signature-canvas';
import imageCompression from 'browser-image-compression';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import trimCanvas from 'trim-canvas';
import StyledDropzone from '../StyledDropzone';
import { Trans } from 'react-i18next';
import AlertDialog from '../dialogs/AlertDialog';
import LabeledOutline from '../LabeledOutline';
import { SignatureDrawBackgroundIcon } from '../icons/SignatureDrawBackgroundIcon';
import { SignatureTypeBackgroundIcon } from '../icons/SignatureTypeBackgroundIcon';
import { SignatureUploadBackgroundIcon } from '../icons/SignatureUploadBackgroundIcon';
import { useAppConfig } from '../context/AppState/AppContext';
import { useAuthContext } from '../context/AuthState/AuthContext';
import { useQuery } from 'react-query';
import axios from '../../lib/axios';
import { useAppArgs } from '../hooks/useAppArgs';
const Fp = require('lodash/fp');

const EXCLUDE_FILE_TYPES = [
  'application/exe',
  'application/dos-exe',
  'application/msdos-windows',
  'application/vnd.microsoft.portable-executable',
  'application/x-exe',
  'application/x-msdos-program',
  'application/x-msdownload',
  'application/x-ms-dos-executable',
  'application/x-winexe',
  'vms/exe',
];
const INCLUDE_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
const ACCEPTED_FILE_TYPES = ['png', 'jpeg'];
const MAX_FILE_SIZE = 2 * (1024 * 1024); // 2MB

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    root: {
      position: 'relative',
      padding: '4px 23px',
    },
    rootWithClose: {
      paddingRight: 48,
    },
    closeButtonWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingRight: 6,
    },
    dialog: {
      '& .MuiPaper-rounded': {
        borderRadius: 4,
      },
      '& .MuiDialog-paper': {
        // overflowY: 'visible'
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
    },
    dialogTitle: {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
    tabButton: {
      '&&': {
        height: '2em',
        textTransform: 'none',
        backgroundColor: '#FFFFFF',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: 'rgba(0, 125, 168, 0.04)',
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        borderColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: '#422C73!important',
        },
      },
    },
    disabledTab: {
      '&&': {
        height: '2em',
        textTransform: 'none',
        color: '#b2b2b2',
        borderColor: '#b2b2b2',
      },
    },
    inputField: {
      '& input': {
        // fontFamily: props.fontFamily ?? '"Dancing Script", cursive',
        // fontWeight: props.fontWeight ?? 400,
        // fontStyle: props.fontStyle ?? 'normal',
        fontSize: props.fontSize ?? '4rem',
        textAlign: props.textAlign ?? 'center',
        textIndent: '8px',
      },
      '& .MuiOutlinedInput': {
        border: `1px solid #d1d5db`,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #d1d5db',
      },
      '& fieldset': {
        border: '1px solid #d1d5db',
        '& hover': {
          border: '1px solid #d1d5db',
        },
        '&.Mui-focused': {
          border: '1px solid #d1d5db',
        },
      },
    },
  }));

interface SignatureFieldProps {
  field: SignatureFieldType;
}

export type ProfileSignatureField = {
  signatureActive?: boolean;
  signatureType?: 'typeSign' | 'drawSign' | 'uploadImageSign';
  signatureImage?: string;
  signatureText?: string;
};

const SignatureField: FC<any> = ({ field }: SignatureFieldProps) => {
  const { labelPosition, labelStyling } = field.config.styling ?? {};
  const { dispatch } = useFormData();
  const [open, setOpen] = useState(false);

  const validationError = useMemo(() => {
    const { required } = field.config;
    if (required && !field.value.length) {
      return t('error empty');
    }
    return '';
  }, [field.value, field.config]);

  useEffect(() => {
    if (field?.config?.readonly || field?.signed) return; //skip validation if field is readonly
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: validationError,
      },
    });
  }, [field, validationError, dispatch]);

  const labelPositionStyling = {
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: 0,
      top: '50%',
      transform: 'translate(calc(100% + 12px), -50%)',
    } as const,
    top: {
      top: 0,
      left: 14,
      transform: 'translate(0, -100%)',
    } as const,
    bottom: {
      bottom: 0,
      left: 14,
      transform: 'translate(0, 100%)',
    } as const,
    'inside-top': {
      top: 0,
      left: 14,
    } as const,
    'within-top': {} as const, // just needed for TS
  }[labelPosition ?? 'within-top'];

  return (
    <div className="flex flex-col" key={field.id + '_field'}>
      <LabeledOutline
        className="relative flex-grow"
        contentWrapperClass="absolute h-full w-full"
        contentClass="inline-block h-full w-full p-2 content-center"
        id="signature"
        label={
          ['within-top', undefined].includes(labelPosition)
            ? field.config.title + (field.config.required ? ' *' : '')
            : undefined
        }
        styling={field.config.styling}
        onClick={() => {
          if (!field?.config?.readonly && !field?.signed) {
            setOpen(true);
          }
        }}
      >
        <SignaturePreview field={field} />
        {!['within-top', undefined].includes(labelPosition) && (
          <InputLabel
            style={{
              position: 'absolute',
              maxWidth: 'none',
              transition: 'none',
              whiteSpace: 'nowrap',
              ...labelPositionStyling,
              ...labelStyling,
              width: 'unset',
              paddingRight: 'unset',
            }}
          >
            {`${field.config.title} ${field.config.required ? '*' : ''}`}
          </InputLabel>
        )}
      </LabeledOutline>
      <SignatureDialog key={open} field={field} open={open} setOpen={setOpen} />
    </div>
  );
};

type SignaturePreviewProps = {
  field: any;
  url: string;
};

const SignaturePreview: FC<any> = ({ field }: SignaturePreviewProps) => {
  return Fp.isEmpty(field.value) ? (
    <div
      style={{
        display: 'contents',
        // position: 'absolute',
        // top: '50%',
        // transform: 'translate(0%, -50%)',
        // maxHeight: 'calc(100% - 16px)'
      }}
    >
      <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{t('Click here to sign...')}</Typography>
    </div>
  ) : (
    <div
      style={{
        display: 'contents',
        // position: 'absolute',
        // top: '50%',
        // transform: 'translate(0%, -50%)',
        // maxHeight: 'calc(100% - 16px)'
      }}
    >
      <img
        style={{
          maxHeight: '100%',
          objectFit: 'contain',
        }}
        alt=""
        src={field.value}
      />
    </div>
  );
};

type SignatureDialogProps = {
  field: SignatureFieldType;
  open: boolean;
  setOpen: Function;
  setUrl: Function;
};

function TabPanel(props: any) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box className="pt-6">{children}</Box>}
    </div>
  );
}

const SignatureDialog: FC<any> = ({ field, open, setOpen }: SignatureDialogProps) => {
  const { dispatch } = useFormData();
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const profileSigCanvas = useRef<ReactSignatureCanvas>(null);
  const textField = useRef<HTMLInputElement>(null);
  const stratusSignatureTextField = useRef<HTMLInputElement>(null);
  const [stratusSignatureText, setStratusSignatureText] = useState('');
  const textFieldStyling = {
    backgroundColor: field.config?.styling?.backgroundColor ?? 'transparent',
    color: field.config?.styling?.color ?? 'black',
    fontStyle: field.config?.styling?.fontStyle ?? 'normal',
    fontWeight: field.config?.styling?.fontWeight ?? 'normal',
    fontFamily: field.config?.styling?.fontFamily ?? '"Dancing Script", cursive',
  };
  const textCanvasStyling = {
    backgroundColor: field.config?.styling?.backgroundColor ?? 'transparent',
    color: field.config?.styling?.color ?? 'black',
    fontStyle: field.config?.styling?.fontStyle === 'italic' ? 'italic' : '',
    fontWeight: field.config?.styling?.fontWeight === 'bold' ? 'bold' : '',
    fontSize: field.config?.styling?.fontSize ?? '32',
    fontFamily: field.config?.styling?.fontFamily ?? '"Dancing Script", cursive',
  };
  const classes = useStyles(textFieldStyling)();
  const [type, setType] = useState(
    field.config.typeSign
      ? 'typeSign'
      : field.config.drawSign
        ? 'drawSign'
        : field.config.uploadImageSign
          ? 'uploadImageSign'
          : '',
  );
  if (type === 'profileSign') textCanvasStyling.fontFamily = '"Dancing Script", cursive'; // TODO: respect the font from the Profile. Currently it's always Dancing Script.
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [textValue, setTextValue] = useState('');
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [placeholder, setPlaceholder] = useState(true);
  const [filePreview, setFilePreview] = useState('');
  const [stratusSignatureImagePreview, setStratusSignatureImagePreview] = useState('');
  const [showSigPreview, setShowSigPreview] = useState(!!field.value);
  const [signatureField, setSignatureField] = useState<ProfileSignatureField>({});
  const queryParams = useAppArgs();
  const { state: appState } = useAppConfig();
  const { state: authState } = useAuthContext();
  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
    if (textField.current) {
      setTextValue('');
    }
    setFilePreview('');
    setSelectedFile([]);
    setPlaceholder(true);
    setSubmitDisabled(true);
  };

  const setFieldValue = (value: string) => {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value,
      },
    });
  };

  const { isLoading: profileSettingLoading } = useQuery(
    ['profileSignature', open],
    async () => {
      const response = await axios.get(
        `https://${appState.tenant.region}.web.${queryParams.fallbackDomain}/api/user/profile-signature`,
        {
          headers: {
            Authorization: 'Bearer ' + authState.token,
          },
        },
      );
      return response.data;
    },
    {
      enabled: open && authState.isAuthenticated && !!appState.tenant.region,
      staleTime: 0,
      onSuccess: (signature: ProfileSignatureField) => {
        if (signature) {
          setSignatureField({
            ...signature,
          });
          if (signature?.signatureText) {
            setStratusSignatureText(signature.signatureText);
          }
        }
      },
      onError: (err) => {
        console.log('Failed to fetch profile signature.');
        setSignatureField({
          signatureActive: false,
        });
      },
    },
  );

  useEffect(() => {
    if (signatureField?.signatureType === 'drawSign') {
      if (signatureField?.signatureActive && profileSigCanvas.current && signatureField?.signatureImage !== undefined) {
        profileSigCanvas.current.fromDataURL(signatureField?.signatureImage);
        profileSigCanvas.current.off();
      }
    }
    if (signatureField?.signatureType === 'uploadImageSign') {
      if (signatureField?.signatureActive && signatureField?.signatureImage !== undefined) {
        setStratusSignatureImagePreview(signatureField?.signatureImage);
      }
    }
    if (signatureField?.signatureType === 'typeSign') {
      if (signatureField?.signatureActive && signatureField?.signatureText !== undefined) {
        setStratusSignatureText(signatureField.signatureText);
        setPlaceholder(true);
      }
    }
    if (type === 'profileSign') {
      setSubmitDisabled(false);
    }
    //TODO::text entry
  }, [signatureField, profileSettingLoading, type]);

  const handleTextCanvas = (inputRef: RefObject<HTMLInputElement>, textValue: string) => {
    const inputDOM = inputRef.current!.querySelector('input');
    if (inputDOM) {
      const canvas = document.createElement('canvas');
      const ratio = window.devicePixelRatio;
      const width = 1280;
      const height = 720;
      const padding = 16;
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.scale(ratio, ratio);
        ctx.font = `${textCanvasStyling.fontStyle} ${textCanvasStyling.fontWeight} ${textCanvasStyling.fontSize}px ${textCanvasStyling.fontFamily}`;
        const textMetrics = ctx.measureText(textValue);
        const textWidth = Math.abs(textMetrics.actualBoundingBoxRight + textMetrics.actualBoundingBoxLeft);
        const textHeight = Math.abs(textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent);
        const lineY = padding / 2 + textHeight;
        console.log(textWidth, textHeight);
        // Draw background
        ctx.fillStyle = 'transparent';
        ctx.fillRect(0, 0, textWidth + padding, textHeight + padding);
        // Draw text
        ctx.fillStyle = textCanvasStyling.color;
        ctx.fillText(textValue, padding / 2, padding / 2 + textMetrics.actualBoundingBoxAscent);
        // Draw Underline
        // if (underline) {
        if (!!false) {
          ctx.strokeStyle = textCanvasStyling.color;
          ctx.moveTo(0, lineY);
          ctx.lineTo(textWidth, lineY);
          ctx.stroke();
        }
      }
      trimCanvas(canvas);
      console.log(width * ratio);
      console.log(height * ratio);
      console.log(canvas.width);
      console.log(canvas.height);
      const dataUrl = canvas.toDataURL('image/png');
      setFieldValue(dataUrl);
    }
  };

  const handleSign = async () => {
    switch (type) {
      case 'drawSign':
        if (sigCanvas.current) {
          if (sigCanvas.current.isEmpty()) {
            setFieldValue('');
          } else {
            const imageURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            const response = await fetch(imageURL);
            const blob = await response.blob();
            const file = new File([blob], 'signature.png', { type: 'image/png', lastModified: Date.now() });
            const image = await imageCompression(file, { maxSizeMB: 0.025, maxIteration: 100 });
            const reader = new FileReader();
            reader.onloadend = () => {
              console.log({
                original: file.size / 1024 / 1024,
                compressed: image.size / 1024 / 1024,
                result: reader.result,
              });
              setFieldValue(reader.result as string);
            };
            reader.readAsDataURL(image);
          }
        }
        break;
      case 'typeSign':
        if (textField.current) {
          handleTextCanvas(textField, textValue);
        }
        break;
      case 'uploadImageSign':
        if (selectedFile[0]) {
          const reader = new FileReader();
          reader.onloadend = () => {
            console.log(reader.result);
            setFieldValue(reader.result as string);
          };
          console.log(selectedFile[0]);
          reader.readAsDataURL(selectedFile[0]);
        } else {
          setFieldValue('');
        }
        break;
      case 'profileSign':
        if (signatureField?.signatureImage !== undefined) {
          setFieldValue(signatureField.signatureImage);
        }
        if (stratusSignatureText && stratusSignatureTextField.current) {
          handleTextCanvas(stratusSignatureTextField, stratusSignatureText);
        }
    }
    handleClose();
  };

  const handleChange = (event: any, newValue: any) => {
    if (newValue !== null && type !== newValue) {
      setType(newValue);
      handleClear();
    }
  };

  const handleTextChange = (e: any) => {
    setTextValue(e.target.value.trimStart());
    if (!Fp.isEmpty(e.target.value.trimStart())) {
      setPlaceholder(false);
      setSubmitDisabled(false);
    } else {
      setPlaceholder(true);
      setSubmitDisabled(true);
    }
  };

  const selectFile = (file: any) => {
    if (!Fp.isEmpty(file)) {
      setPlaceholder(false);
      setSubmitDisabled(false);
    } else {
      setPlaceholder(true);
      setSubmitDisabled(true);
    }
    setSelectedFile(file);
    setFilePreview(URL.createObjectURL(file[0]));
    console.log(file);
    // console.log(URL.createObjectURL(file))
  };

  return (
    <AlertDialog
      type="white"
      open={open}
      setOpen={(open: boolean) => setOpen(open)}
      onClose={handleClose}
      title={field.config.title}
      maxWidth="sm"
      content={
        <span style={{ visibility: showSigPreview ? 'hidden' : undefined }}>
          {t('signature field:dialog:description') + ' '}
          {authState.isAuthenticated && (
            <>
              {t('signature field:dialog:profile link') + ' '}
              <Link
                href={`http://${queryParams.tenant.slug}.tenant.${queryParams.domain}/admin/profile/#profile-signature`}
                target="_blank"
                underline="always"
                rel="noopener"
                style={{
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  border: 'none',
                  fontStyle: 'italic',
                  fontWeight: 400,
                  color: '#7051ad',
                }}
              >
                {t('signature field:dialog:profile signature')}
              </Link>
              {'.'}
            </>
          )}
        </span>
      }
      resourceMarginBottom={0}
      resource={
        <>
          <ToggleButtonGroup
            style={{ visibility: showSigPreview ? 'hidden' : undefined }}
            className="w-full justify-center"
            value={type}
            exclusive
            color="primary"
            onChange={handleChange}
          >
            {field.config.typeSign && (
              <ToggleButton classes={{ root: classes.tabButton }} value="typeSign">
                {t('Type')}
              </ToggleButton>
            )}
            {field.config.drawSign && (
              <ToggleButton classes={{ root: classes.tabButton }} value="drawSign">
                {t('Draw')}
              </ToggleButton>
            )}
            {field.config.uploadImageSign && (
              <ToggleButton classes={{ root: classes.tabButton }} value="uploadImageSign">
                {t('Upload')}
              </ToggleButton>
            )}
            {
              <ToggleButton
                classes={{ root: !signatureField?.signatureActive ? classes.disabledTab : classes.tabButton }}
                disabled={!signatureField?.signatureActive || profileSettingLoading}
                value="profileSign"
              >
                {t('Profile')}
              </ToggleButton>
            }
          </ToggleButtonGroup>
          {showSigPreview ? (
            <Box className="pt-6">
              <div className="relative pointer-events-none">
                <StyledDropzone
                  className="relative h-32"
                  files={{ selectedFiles: [], setSelectedFiles: () => {} }}
                  preview={field.value}
                />
              </div>
            </Box>
          ) : (
            <>
              <TabPanel value={type} index={'drawSign'}>
                <div className="relative">
                  {placeholder && (
                    <div className="absolute flex flex-col p-4 w-full h-full items-center justify-center">
                      <div className="flex-grow pb-2 flex justify-center">
                        <SignatureDrawBackgroundIcon className="h-full w-auto" />
                      </div>
                      <div>
                        <Typography variant="body1" style={{ lineHeight: '1.2em', color: 'gray', fontFamily: '"Open Sans", Muli, Roboto, Helvetica, Arial, sans-serif' }}>
                          {t('signature field:dialog:draw description')}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <ReactSignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      className: 'sigCanvas relative w-full border border-gray-300 rounded',
                      style: { height: '8rem' },
                    }}
                    onBegin={() => {
                      setPlaceholder(false);
                      setSubmitDisabled(false);
                    }} // Find a different way to do this? makes the signing lag a bit at the start
                  />
                </div>
              </TabPanel>
              <TabPanel value={type} index={'typeSign'}>
                <div className="relative">
                  {placeholder && (
                    <div className="absolute flex flex-col p-4 w-full h-full items-center justify-center">
                      <div className="flex-grow pb-2 flex justify-center">
                        <SignatureTypeBackgroundIcon className="h-full w-auto" />
                      </div>
                      <div>
                        <Typography variant="body1" style={{ lineHeight: '1.2em', color: 'gray', fontFamily: '"Open Sans", Muli, Roboto, Helvetica, Arial, sans-serif' }}>
                          {t('signature field:dialog:type description')}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <TextField
                    autoFocus
                    ref={textField}
                    className={`${classes.inputField} relative`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={textValue || ''}
                    onChange={handleTextChange}
                    InputProps={{
                      style: {
                        overflowY: 'hidden',
                        overflowX: 'hidden',
                        fontFamily: field.config?.styling?.fontFamily ?? '"Dancing Script", cursive',
                      },
                    }}
                    inputProps={{
                      minLength: field.config.minLength ?? 0,
                      maxLength: field.config.maxLength ?? 255,
                      style: { boxSizing: 'border-box', height: '8rem', fontSize: '36px' },
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel value={type} index={'uploadImageSign'}>
                <div className="relative">
                  {placeholder && (
                    <div className="absolute flex flex-col p-4 w-full h-full items-center justify-center">
                      <div className="flex-grow pb-2 flex justify-center">
                        <SignatureUploadBackgroundIcon className="h-full w-auto" />
                      </div>
                      <div>
                        <Typography variant="body1" style={{ lineHeight: '1.2em', color: 'gray', fontFamily: '"Open Sans", Muli, Roboto, Helvetica, Arial, sans-serif' }}>
                          {t('signature field:dialog:upload description')}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <StyledDropzone
                    className="relative h-32"
                    maxSize={MAX_FILE_SIZE}
                    maxFiles={1}
                    excludeTypes={EXCLUDE_FILE_TYPES}
                    includeTypes={INCLUDE_FILE_TYPES}
                    files={{ selectedFiles: selectedFile, setSelectedFiles: selectFile }}
                    errorMessages={{
                      size: (
                        <Trans
                          i18nKey="upload:file:max file size error"
                          values={{ size: MAX_FILE_SIZE / (1024 * 1024), unit: 'MB' }}
                        />
                      ),
                      blocked: (
                        <Trans
                          i18nKey="upload:file:blocked file error"
                          values={{ fileTypes: ACCEPTED_FILE_TYPES.join(', ') }}
                        />
                      ),
                      exe: (
                        <Trans
                          i18nKey="upload:file:blocked file error"
                          values={{ fileTypes: ACCEPTED_FILE_TYPES.join(', ') }}
                        />
                      ),
                    }}
                    preview={filePreview}
                    multiple={false}
                  />
                </div>
              </TabPanel>
              <TabPanel value={type} index={'profileSign'}>
                {signatureField?.signatureActive && signatureField?.signatureType === 'drawSign' && (
                  <ReactSignatureCanvas
                    ref={profileSigCanvas}
                    canvasProps={{
                      className: 'sigCanvas relative w-full border border-gray-300 rounded',
                      style: { height: '8rem' },
                    }}
                    onBegin={() => setPlaceholder(false)} // Find a different way to do this? makes the signing lag a bit at the start
                  />
                )}
                {signatureField?.signatureActive && signatureField?.signatureType === 'uploadImageSign' && (
                  <StyledDropzone
                    className="relative h-32"
                    maxSize={MAX_FILE_SIZE}
                    maxFiles={1}
                    excludeTypes={EXCLUDE_FILE_TYPES}
                    includeTypes={INCLUDE_FILE_TYPES}
                    files={{ selectedFiles: selectedFile, setSelectedFiles: selectFile }}
                    errorMessages={{
                      size: (
                        <Trans
                          i18nKey="upload:file:max file size error"
                          values={{ size: MAX_FILE_SIZE / (1024 * 1024), unit: 'MB' }}
                        />
                      ),
                      blocked: (
                        <Trans
                          i18nKey="upload:file:blocked file error"
                          values={{ fileTypes: ACCEPTED_FILE_TYPES.join(', ') }}
                        />
                      ),
                      exe: (
                        <Trans
                          i18nKey="upload:file:blocked file error"
                          values={{ fileTypes: ACCEPTED_FILE_TYPES.join(', ') }}
                        />
                      ),
                    }}
                    preview={stratusSignatureImagePreview}
                    multiple={false}
                    disabled={true}
                  />
                )}
                {signatureField?.signatureActive && signatureField?.signatureType === 'typeSign' && (
                  <TextField
                    autoFocus
                    ref={stratusSignatureTextField}
                    className={`${classes.inputField} relative`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={stratusSignatureText}
                    InputProps={{
                      readOnly: true,
                      style: {
                        overflowY: 'hidden',
                        overflowX: 'hidden',
                        fontFamily: '"Dancing Script", cursive', // TODO: set font to profile font if there are multiple options. Right now it's always Dancing Script cursive.
                        fontWeight: 'bold',
                      },
                    }}
                    inputProps={{
                      minLength: field.config.minLength ?? 0,
                      maxLength: field.config.maxLength ?? 255,
                      style: { boxSizing: 'border-box', height: '8rem', fontSize: '36px' },
                    }}
                  />
                )}
              </TabPanel>
            </>
          )}
        </>
      }
      action={handleSign}
      buttonsText={[t('Accept and Sign')]}
      disabledBtn={isSubmitDisabled}
      secondaryButton={false}
      tertiaryButton={{
        text: t('Clear'),
        action: () => {
          handleClear();
          setShowSigPreview(false);
        },
        disabled: type === 'profileSign',
      }}
    />
  );
};

export default memo(SignatureField);
