const Form = {
  id: '8e95f34b59a94eecac385790ae4857b0',
  title: 'free-form-test-positioning',
  creator: 'konicaminoltamarketplace_60a507f5ff82a85efc5e1c2dbd4b90981f68ecdd',
  editor: 'konicaminoltamarketplace_60a507f5ff82a85efc5e1c2dbd4b90981f68ecdd',
  valid: true,
  advanced: true,
  metadataKeys:
    'text1,number1,textarea1,dropdown1,checkbox1,square1,circle1,triangle1,star1,diamond1,asterisk1,plus1,minus1,line1',
  created: 1716316264141,
  updated: 1717686555720,
  formStatus: 'published',
  formGroup: {
    id: 'ed956ae14e3e4a87bedfce09a4a7e097',
  },
  formDefinition: {
    targetMfpId: 'custom',
    config: {
      pageCount: 1,
      gridSize: 1,
      styling: {
        width: {
          num: 800,
          unit: 'px',
        },
        height: {
          num: 800,
          unit: 'px',
        },
        backgroundColor: '#ffffffff',
      },
      authRequired: false,
      authType: 'anonymous',
      requireRecaptcha: true,
    },
    pages: [
      {
        id: 'dH88r3qnru_xofNbNRifP',
        config: {
          styling: {
            backgroundColor: '#CCFFF5',
            backgroundImage: {
              enabled: false,
              image: null,
              opacity: 100,
              fitType: 'fit',
            },
          },
        },
      },
      {
        id: 'eI88r3qnru_xofNbNRifQ',
        config: {
          styling: {
            backgroundColor: '#FFCCD6',
            backgroundImage: {
              enabled: false,
              image: null,
              opacity: 100,
              fitType: 'fit',
            },
          },
        },
      },
    ],
    fields: [
      {
        type: 'dropdown',
        id: 'UNmGvge6d4k0g6jAzst5n',
        config: {
          variable: 'dropdown1',
          title: 'Label below the field',
          defaultValue: '',
          required: false,
          readonly: false,
          multiSelect: false,
          buttonsList: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          options: [
            {
              id: 'VLhQaJ70kLb6UZwZ5iB_D',
              label: 'one',
              value: '1',
              show: true,
              isDefault: false,
            },
            {
              id: 'wdMOpdaYJJXISV9K6FPeY',
              label: 'two',
              value: '2',
              show: true,
              isDefault: false,
            },
          ],
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 74,
            y: 337,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'below',
            width: 360,
            height: 40,
            labelPosition: 'bottom',
          },
        },
      },
      {
        type: 'number',
        id: '_NmwJcUK2ZnqGr57gShBj',
        config: {
          variable: 'number1',
          title: 'Label on top of field',
          defaultValue: '',
          minValue: '0',
          maxValue: '99999999',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 317,
            y: 135,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
            labelPosition: 'top',
          },
        },
      },
      {
        type: 'number',
        id: '_NmwJcUK2ZnqGr57gShBi',
        config: {
          variable: 'number1',
          title: 'Label on left of field',
          defaultValue: '',
          minValue: '0',
          maxValue: '99999999',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 317,
            y: 185,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
            labelPosition: 'left',
          },
        },
      },
      {
        type: 'textarea',
        id: 'Ms70eVqmBJclylJNUHMYk',
        config: {
          variable: 'textarea1',
          title: 'Label on right of field',
          defaultValue: '',
          minLength: '0',
          maxLength: '5000',
          required: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 55,
            y: 232,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 86,
            labelPosition: 'right',
          },
        },
      },
      {
        type: 'date',
        id: 'Ms70eVqmBJclylJNU6MYk',
        config: {
          variable: 'date1',
          title: 'Label inside top of field',
          defaultValue: '',
          minValue: '08/01/2023 10:00 am',
          maxValue: '09/05/2029 12:30 pm',
          value: '06/26/2024 12:30 pm',
          returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
          defaultToNow: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 155,
            y: 422,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'right',
            width: 360,
            height: 86,
            labelPosition: 'inside-top',
          },
        },
      },
      {
        type: 'text',
        id: 'MQOXM97RORiYZg8QEmqpf',
        config: {
          variable: 'text1',
          title: 'Label within top of border of field',
          defaultValue: '',
          minLength: '0',
          maxLength: '1000',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 55,
            y: 76,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
            labelPosition: 'within-top',
          },
        },
      },
    ],
  },
};
export default Form;
