import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { AppState, INITIAL_NODE_CONFIG } from '../../../interfaces';
import { AppActionsEnum, AppReducer } from './app-state-reducer';

type AppContextType = {
  state: AppState;
  setAppConfig: (data: Partial<AppState>) => void;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [appState, dispatch] = useReducer(AppReducer, INITIAL_NODE_CONFIG);

  const setAppConfig = useCallback(
    (data: Partial<AppState>) => {
      dispatch({ type: AppActionsEnum.SET_CONFIG, payload: data });
    },
    [dispatch],
  );

  return (
    <AppContext.Provider
      value={{
        state: appState,
        setAppConfig,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppConfig = () => {
  const context = useContext(AppContext);
  if (!context)
    throw Error(
      'Form Data Context not found. Use this hook with FormDataContextProvider.',
    );
  return context;
};
