import { useTranslation } from 'react-i18next';
import { useFormData } from '../../context/FormState/FormDataContext';
import { FormActionType } from '../../context/FormState/form-state-reducer';

function PageSelector() {
  const { state: formState, dispatch } = useFormData();
  const { t } = useTranslation();
  const pageLabels = Array.from(
    { length: formState.numPages },
    (_, i) => i + 1,
  );
  return (
    <div className="self-center">
      <span>{t('Page:')}</span>
      <select
        className="mx-1"
        value={formState.currentPage.number}
        onChange={(e) => {
          dispatch({ type: FormActionType.SET_PAGE, payload: +e.target.value });
        }}
      >
        {pageLabels.map((pageNum: number) => {
          return (
            <option key={pageNum} value={pageNum}>
              {pageNum}
            </option>
          );
        })}
      </select>
      <span>{`${t('of')} ${formState.numPages}`}</span>
    </div>
  );
}

export default PageSelector;
