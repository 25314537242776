import { SnackbarMessage, SnackbarMessageType } from './snackbar-state';

export enum SnackbarActionType {
  SHOW = 'show',
  HIDE = 'hide',
}

export type ShowAppSnackbarAction = {
  type: SnackbarActionType.SHOW;
  payload: {
    message: SnackbarMessage;
    type: SnackbarMessageType;
  };
};

export type HideAppSnackbarAction = {
  type: SnackbarActionType.HIDE;
};

export type SnackbarAction = ShowAppSnackbarAction | HideAppSnackbarAction;
