import { lazy, Suspense } from 'react';

const AltchaWidget = lazy(() => import('./AltchaWidget'));

// Define interfaces for configuration and challenge
interface Strings {
  error: string;
  expired: string;
  footer: string;
  label: string;
  verified: string;
  verifying: string;
  waitAlert: string;
}

interface Challenge {
  algorithm: string;
  challenge: string;
  maxnumber?: number;
  salt: string;
  signature: string;
}

export interface Configure {
  auto?: 'onfocus' | 'onload' | 'onsubmit';
  challenge?: Challenge;
  challengeurl?: string;
  debug?: boolean;
  expire?: number;
  autorenew?: boolean;
  hidefooter?: boolean;
  hidelogo?: boolean;
  maxnumber?: number;
  mockerror?: boolean;
  name?: string;
  refetchonexpire?: boolean;
  spamfilter?: boolean;
  strings?: Partial<Strings>;
  test?: boolean | number;
  verifyurl?: string;
  workers?: number;
}

function Altcha(props: Configure) {
  // DEV NOTE::this component is being lazy loaded to suppress a bug that causes the browser on the MFP to fail due to newer syntax
  return (
    <Suspense fallback={null}>
      <AltchaWidget {...props} />
    </Suspense>
  );
}

export default Altcha;
