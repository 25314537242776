import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import STCLogo from '../../../assets/logo_scantrip_white.svg';
import StratusLogo from '../../../assets/logo_stratus_white.svg';
import Logout from '../../../assets/logout.svg';
import { useAppArgs } from '../../hooks/useAppArgs';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  controls: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '& *': {
      margin: '0',
    },
  },
  header: {
    background: '#323232',
    display: 'flex',
    color: 'white',
    height: 50,
    padding: 7,
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  logo: {
    display: 'inline-flex',
  },
  text: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  text_holder: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  logout_text: {
    fontSize: 'medium',
    color: 'white',
  },
});

const Header = (props) => {
  const classes = useStyles();
  const { search } = useLocation();
  const { domain } = useAppArgs();
  let logo = STCLogo;
  const metadataString = new URLSearchParams(search).get('metadata');
  if (metadataString) {
    try {
      const metadata = JSON.parse(decodeURIComponent(metadataString));
      if (metadata?.stratus) {
        logo = StratusLogo;
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <img src={logo} alt="" />
      </div>
      <IconButton
        style={{
          background: 'inherit',
          padding: '0px 0px',
          margin: '5px',
        }}
        onClick={() => {
          window.location.href = `https://mfp.${domain}/logout`;
        }}
      >
        <img src={Logout} alt="Logout" />
      </IconButton>
    </div>
  );
};

export default memo(Header);
