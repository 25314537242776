import React, { useMemo, useReducer } from 'react';
import { SnackbarMessage, SnackbarState } from './snackbar-state';
import { SnackbarAction, SnackbarActionType } from './snackbar-actions';
import { snackbarStateReducer } from './snackbar-reducer';

type SnackbarActionsApi = {
  showSuccessMessage: (message: SnackbarMessage) => void;
  showErrorMessage: (message: SnackbarMessage) => void;
  hideMessage: () => void;
};

export type SnackbarContextType = {
  state: SnackbarState;
  dispatch: (action: SnackbarAction) => void;
};

export const SnackbarContext = React.createContext<SnackbarContextType>(
  {} as SnackbarContextType,
);
export const SnackbarApiContext = React.createContext<SnackbarActionsApi>(
  {} as SnackbarActionsApi,
);

export const SnackbarContextProvider = ({
  initialState,
  children,
}: {
  initialState: SnackbarState;
  children?: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(snackbarStateReducer, initialState);

  const snackbarApi = useMemo(() => {
    const showSuccessMessage = (message: SnackbarMessage) => {
      dispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message,
          type: 'success',
        },
      });
    };
    const showErrorMessage = (message: SnackbarMessage) => {
      dispatch({
        type: SnackbarActionType.SHOW,
        payload: {
          message,
          type: 'error',
        },
      });
    };
    const hideMessage = () => {
      dispatch({
        type: SnackbarActionType.HIDE,
      });
    };

    return { showSuccessMessage, showErrorMessage, hideMessage } as const;
  }, []);

  return (
    <SnackbarApiContext.Provider value={snackbarApi}>
      <SnackbarContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </SnackbarContext.Provider>
    </SnackbarApiContext.Provider>
  );
};

export * from './snackbar-actions';
export * from './snackbar-reducer';
export * from './snackbar-state';
