import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useFormData } from './context/FormState/FormDataContext';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { AppContext } from './context/AppState/AppContext';

export const DebugWindow = () => {
  const { state: formState } = useFormData();
  const { state: appState } = useContext(AppContext);
  const { dispatch } = useContext(MessageBoxContext);
  const [show, setShow] = useState(false);
  const [showErrorTriggers, setShowErrorTriggers] = useState(false);
  const [showAppState, setShowAppState] = useState(false);
  const [showFormData, setShowFormData] = useState(false);

  function showError(title: string, message: string) {
    dispatch({
      type: MessageBoxStateActions.MESSAGE_BOX,
      payload: {
        open: true,
        boxType: MessageBoxType.Ok,
        title: title,
        message: message,
      },
    });
  }

  return (
    <>
      <Button
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '10em',
        }}
        variant="contained"
        onClick={() => setShow((value) => !value)}
      >
        Show Debug
      </Button>
      {show && (
        <div
          id="debug"
          style={{
            display: 'block',
            fontSize: '12px',
            zIndex: '99',
            resize: 'horizontal',
            position: 'absolute',
            height: '95vh',
            width: '30%',
            overflow: 'scroll',
            left: '0',
            top: '5%',
            color: 'black',
            background: 'beige',
          }}
        >
          <Section
            title="Trigger Error Screens"
            body={
              <Grid
                style={{ width: '100%' }}
                container
                direction="row"
                spacing={1}
                justifyContent="flex-start"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(
                        t('error invalidConfig title'),
                        t('error invalidConfig body'),
                      )
                    }
                  >
                    Invalid Config
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(t('error formError'), t('error fetchConfig'))
                    }
                  >
                    Node Config Fetch Error
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(t('error formError'), t('error fetchProcess'))
                    }
                  >
                    Process Data Fetch Error
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(t('error formError'), t('error fetchForm'))
                    }
                  >
                    Form Data Fetch Error
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(t('error formError'), t('error notPublished'))
                    }
                  >
                    Form Not Published Error
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(
                        t('error formSubmission'),
                        t('error fetchMetadata'),
                      )
                    }
                  >
                    Metadata Fetch Error
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(
                        t('error formSubmission'),
                        t('error uploadMetadata'),
                      )
                    }
                  >
                    Metadata Upload Error
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      showError(
                        t('error formSubmission'),
                        t('error sendReport'),
                      )
                    }
                  >
                    Report Send Error
                  </Button>
                </Grid>
              </Grid>
            }
            show={showErrorTriggers}
            setShow={setShowErrorTriggers}
          />

          <Section
            title="App State"
            body={
              <>
                <pre>
                  <code>{JSON.stringify(appState, null, 2)}</code>
                </pre>
              </>
            }
            show={showAppState}
            setShow={setShowAppState}
          />
          <Section
            title="FormData"
            body={
              <pre>
                <code>{JSON.stringify(formState, null, 2)}</code>
              </pre>
            }
            show={showFormData}
            setShow={setShowFormData}
          />
        </div>
      )}
    </>
  );
};

type Props = {
  title: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  body: JSX.Element;
};

const Section = ({ title, body, show, setShow }: Props) => {
  return (
    <div style={{ marginBottom: '0.5em' }}>
      <div
        style={{
          fontWeight: 'bold',
          backgroundColor: 'darkred',
          color: 'beige',
          textAlign: 'center',
          padding: '0.5em 0 0.5em 0',
        }}
        onClick={() => setShow((val) => !val)}
      >
        {show ? <ExpandLess /> : <ExpandMore />}
        {title}
        {show ? <ExpandLess /> : <ExpandMore />}
      </div>
      {show && body}
    </div>
  );
};
