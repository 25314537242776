import {
  AsteriskTool,
  CircleTool,
  DiamondTool,
  GenericFieldStyling,
  LineTool,
  MinusTool,
  PlusTool,
  SquareTool,
  StarTool,
  TriangleTool,
} from '../../interfaces/FormFieldTypes';
import { roundCorners } from 'svg-round-corners';
import { memo } from 'react';

interface SvgProps {
  styling: Pick<
    GenericFieldStyling,
    'borderStyle' | 'borderColor' | 'borderRadius' | 'color' | 'borderWidth' | 'backgroundColor'
  >;
}

const getRoundedCorners = (path: string, rad: number) => {
  return roundCorners(path, rad ?? 0).path;
};

const borderStyleStroke = (
  borderStyle: GenericFieldStyling['borderStyle'],
  borderWidth: GenericFieldStyling['borderWidth'],
) => {
  return {
    dashed: `${borderWidth * 2} ${borderWidth * 2}`,
    dotted: `${borderWidth / 4} ${borderWidth * 2}`,
    solid: undefined,
  }[borderStyle];
};

const Square = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    overflow={'visible'}
    viewBox="0 0 61 61"
    fill={styling.backgroundColor ?? 'transparent'}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      d={getRoundedCorners('M0,0H60V60H0Z', styling.borderRadius)}
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      strokeWidth={styling.borderWidth}
      stroke="currentColor"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

const Circle = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    overflow={'visible'}
    viewBox="0 0 61 61"
    fill={styling.backgroundColor}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      d={getRoundedCorners('M30,0A30,30,0,1,1,0,30,30,30,0,0,1,30,0Z', styling.borderRadius)}
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      stroke="currentColor"
      strokeWidth={styling.borderWidth}
    />
  </svg>
);

const Triangle = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    overflow={'visible'}
    height="100%"
    viewBox="0 0 61.718 54.312"
    fill={styling.backgroundColor}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      d={getRoundedCorners('M30,0,60,52.8H0Z', styling.borderRadius)}
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      stroke={'currentColor'}
      strokeWidth={styling.borderWidth}
    />
  </svg>
);

const Star = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    overflow={'visible'}
    height="100%"
    viewBox="0 0 63.116 62.914"
    fill={styling.backgroundColor}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      d={getRoundedCorners(
        'M49.281,60.356l-18.4-14-18.4,14L19.4,37.743.884,24.492H23.692L30.884.52l7.192,23.972H60.884L42.363,37.743Z',
        styling.borderRadius,
      )}
      transform="translate(-0.884 -0.52)"
      stroke="currentColor"
      strokeWidth={styling.borderWidth}
    />
  </svg>
);

const Diamond = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    overflow={'visible'}
    viewBox="0 0 62.332 29.599"
    fill={styling.backgroundColor ?? 'transparent'}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      d={getRoundedCorners('M-12472.845-264.174l29.287,14-29.287,14.494-30.713-14.494Z', styling.borderRadius)}
      transform="translate(12503.558 264.174)"
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      stroke={'currentColor'}
      strokeWidth={styling.borderWidth}
    />
  </svg>
);

const Asterisk = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    overflow={'visible'}
    viewBox="0 0 61.397 59.511"
    fill={styling.backgroundColor ?? 'transparent'}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      d={getRoundedCorners(
        'M39.561,33.2H23.727l.353-15.794L10.071,27.087l-8.3-13.055L18.381,3.93,1.849-6.252l8.219-13.132L24.043-9.558l-.308-15.755H39.491L39.137-9.453l14.147-9.73,8.439,13.04L45,3.9,61.775,14.04l-8.461,13L39.076,17.014Z',
        styling.borderRadius,
      )}
      stroke="currentColor"
      transform="translate(-1.775 25.313)"
      strokeWidth={styling.borderWidth}
    />
  </svg>
);

const Plus = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    overflow={'visible'}
    viewBox="0 0 61 60.284"
    fill={styling.backgroundColor ?? 'transparent'}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      stroke={'currentColor'}
      strokeWidth={styling.borderWidth}
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      d={getRoundedCorners(
        'M40.481,37.721h-15.9V15.9H2.531V.261h22.05V-21.562h15.9V.261h22.05V15.9H40.481Z',
        styling.borderRadius,
      )}
      transform="translate(-2.531 21.563)"
    />
  </svg>
);

const Minus = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    overflow={'visible'}
    viewBox="0 0 61 16.336"
    fill={styling.backgroundColor ?? 'transparent'}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <path
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      vectorEffect="non-scaling-stroke"
      d={getRoundedCorners('M58.875,16.632h-60V1.3h60Z', styling.borderRadius)}
      stroke="currentColor"
      transform="translate(1.125 -1.297)"
      strokeWidth={styling.borderWidth}
    />
  </svg>
);

const Line = ({ styling }: SvgProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill={styling.backgroundColor ?? 'transparent'}
    preserveAspectRatio="none"
    color={styling.borderColor}
  >
    <line
      x1="0"
      y1="12"
      x2="24"
      y2="12"
      stroke="currentColor"
      strokeDasharray={borderStyleStroke(styling.borderStyle, styling.borderWidth)}
      strokeWidth={styling.borderWidth}
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export const shape = {
  square: Square,
  circle: Circle,
  triangle: Triangle,
  star: Star,
  diamond: Diamond,
  asterisk: Asterisk,
  plus: Plus,
  minus: Minus,
  line: Line,
};

type Props = {
  field:
    | CircleTool
    | SquareTool
    | TriangleTool
    | StarTool
    | DiamondTool
    | AsteriskTool
    | PlusTool
    | MinusTool
    | LineTool;
};

const ShapeTool = (props: Props) => {
  const Shape = shape[props.field.type as keyof typeof shape];
  const { borderStyle, borderWidth, backgroundColor, color, borderRadius, borderColor } = props.field.config.styling!;
  return (
    <div
      className="flex items-center"
      style={
        props.field.config?.styling?.height && props.field.config?.styling?.width
          ? {
              width: props.field.config.styling.width + 'px',
              height: props.field.config.styling.height + 'px',
            }
          : {}
      }
    >
      <Shape styling={{ color, borderRadius, borderStyle, borderWidth, backgroundColor, borderColor }} />
    </div>
  );
};

export default memo(ShapeTool);
