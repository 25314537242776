import { AUTH_METHODS, DOMAIN, REGION } from '../interfaces';
import axios from './axios';
import { authWithFormsSamlAppClient } from './formApi';

let domain: DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
interface CongitoUrlParamObject {
  response_type: string;
  client_id: string;
  redirect_uri: string;
  state: string;
  identity_provider?: string;
  scope?: string;
}

export async function getToken(
  slug: string,
  region: string,
  clientId: string,
  params: {
    grant_type: string;
    client_id?: string;
    code: string;
    redirect_uri: string;
  },
  clientSecret?: string
) {

  const headers: { headers: { [key: string]: string } } =  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }


  if (clientSecret) {
    headers.headers.Authorization = `Basic ${btoa(`${clientId}:${clientSecret}`)}`
  }

  const response = await axios.post(
    `https://${slug}.auth.${region}.amazoncognito.com/oauth2/token?${new URLSearchParams(
      params,
    ).toString()}`,
    null,
    headers
  );
  return response.data.access_token;
}

export async function getSsoUserToken(
  slug: string,
  region: string,
  clientId: string,
  params: {
    grant_type: string;
    client_id?: string;
    code: string;
    redirect_uri: string;
  },
  domain: string
) {
  try {
    const token = await getToken(slug, region, clientId, params);
    const validUser = await  authWithFormsSamlAppClient(region, domain, token)

    if (!validUser) {
      throw new Error('Invalid user')
    }

    return token
  } catch (error) {
    console.error(error);
    throw new Error('failed to authenticate.');
  }
}

export function getSigninUrl(
  client_id: string,
  slug: string,
  region: REGION,
  clientSecret: string,
  authMethod: AUTH_METHODS,
  provider?: string 
) {
  let baseUrl = `https://${slug}.auth.${region}.amazoncognito.com/`;
  let scope = 'openid email profile';
  const isSaml = authMethod === 'sso-non-stratus-user' || authMethod === 'sso-stratus-user';

  const paramObject: CongitoUrlParamObject = {
    response_type: 'code',
    client_id,
    redirect_uri: `http${domain === 'localhost' ? '' : 's'}://${
      window.location.host
    }/sso/login`,
    state: btoa(
      JSON.stringify({
        client_id,
        clientSecret,
        slug,
        path: window.location.pathname + window.location.search,
        region,
        authMethod
      }),
    ),
    scope: scope
  };

  if (isSaml) {
    scope += ' forms/saml';
    baseUrl += 'authorize?';
    paramObject.identity_provider = provider;
  } else {
    scope += ' aws.cognito.signin.user.admin';
    baseUrl += 'login?';
  }

  paramObject.scope = scope;

  const params = new URLSearchParams();

  for (const key in paramObject) {
    const value = paramObject[key as keyof CongitoUrlParamObject]

    if (value) {
      params.append(key, value);
    }
  }
  const url = `${baseUrl}${params.toString()}`;
  return url;
}


