import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';

function Standby(props: { onRetry: () => void }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid
      container
      style={{
        backgroundColor: 'white',
        padding: '0 20% 0 20%',
      }}
      justifyContent="center"
      alignContent="center"
      xs
      direction="column"
    >
      <Grid item className="my-3">
        <Typography align="center" variant="h3">
          {t('Login required to view form')}
        </Typography>
      </Grid>
      <Grid item className="my-3">
        <Typography align="center" variant="body1">
          {t('standby:note')}
        </Typography>
      </Grid>
      <Grid item className="mx-auto my-3">
        <Button color="primary" variant="contained" onClick={props.onRetry}>
          {t('Retry')}
        </Button>
      </Grid>
      <Grid item className="mx-auto">
        <ThreeDots
          color={theme.palette.primary.main}
          height="150"
          width="200"
        />
      </Grid>
    </Grid>
  );
}

export default Standby;
