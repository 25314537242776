import { useContext } from 'react';
import { SnackbarApiContext } from '../context/Snackbar';

export const useSnackbarApi = () => {
  const context = useContext(SnackbarApiContext);

  if (!context) {
    throw new Error(
      'useSnackbarApi has to be used within <SnackbarApiContext.Provider>',
    );
  }

  return context;
};
