import { LabelField as LabelFieldType } from '../../interfaces/FormFieldTypes';
import { memo } from 'react';

type Props = {
  field: LabelFieldType;
};

const LabelField = (props: Props) => {
  return (
    <div
      className="flex flex-col py-2 break-all"
      style={{ ...props.field.config.styling, lineHeight: '1.2em', overflow: 'hidden' }}
    >
      {props.field.config.title}
    </div>
  );
};

export default memo(LabelField);
