import { Button, ButtonGroup, Checkbox, Grid, Typography } from '@material-ui/core';
import { CheckboxField as CheckboxFieldType } from '../../interfaces/FormFieldTypes';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { memo } from 'react';

type Props = {
  field: CheckboxFieldType;
};

const CheckboxField = memo(({ field }: Props) => {
  const { dispatch } = useFormData();

  function handleInputChange(e: any) {
    dispatch({
      type: FormActionType.SET_FIELD_VALUE,
      payload: {
        id: field.id,
        value: e.target.checked,
      },
    });
  }
  return (
    <Grid
      container
      direction={'row'}
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      item
      xs={12}
      style={field.config.styling}
    >
      {!field.config.buttonsList && (
        <Grid item>
          <Checkbox
            disabled={field.config.readonly}
            color="primary"
            checked={field.value}
            onChange={handleInputChange}
          />
        </Grid>
      )}
      <Grid item xs>
        <Typography
          variant="body1"
          style={{
            wordBreak: 'break-word',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            fontWeight: 'inherit',
            fontStyle: 'inherit',
            textDecorationLine: 'inherit',
            textAlign: 'inherit',
          }}
        >
          {field.config.title}
        </Typography>
      </Grid>
      {field.config.buttonsList && <ButtonList field={field} />}
    </Grid>
  );
});

const ButtonList = memo(({ field }: Props) => {
  const { dispatch } = useFormData();
  return (
    <Grid item xs={6}>
      <ButtonGroup className="w-full">
        <Button
          className="w-full"
          variant="contained"
          color={field.value ? 'primary' : 'default'}
          disabled={field.config.readonly}
          style={{
            minHeight: '100%',
            opacity: field.value ? '100%' : '50%',
            fontSize: '0.6em',
          }}
          onClick={() =>
            dispatch({
              type: FormActionType.SET_FIELD_VALUE,
              payload: {
                id: field.id,
                value: true,
              },
            })
          }
        >
          <span className="break-all">{field.config.trueLabel}</span>
        </Button>
        <Button
          variant="contained"
          className="w-full"
          color={!field.value ? 'primary' : 'default'}
          disabled={field.config.readonly}
          style={{
            minHeight: '100%',
            opacity: !field.value ? '100%' : '50%',
            fontSize: '0.6em',
          }}
          onClick={() =>
            dispatch({
              type: FormActionType.SET_FIELD_VALUE,
              payload: {
                id: field.id,
                value: false,
              },
            })
          }
        >
          <span className="break-all">{field.config.falseLabel}</span>
        </Button>
      </ButtonGroup>
    </Grid>
  );
});

export default memo(CheckboxField);
