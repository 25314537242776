import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { FormDataType, INITIAL_FORM_CONFIG } from '../../../interfaces';
import { FormActions } from './form-state-actions';
import { FormActionType, formStateReducer } from './form-state-reducer';
import { useLocation } from 'react-router-dom';

type FormContextType = {
  state: FormDataType;
  dispatch: (actions: FormActions) => void;
};

export const FormDataContext = createContext<FormContextType>(
  {} as FormContextType,
);

export const FormDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [formState, formDispatch] = useReducer(
    formStateReducer,
    INITIAL_FORM_CONFIG,
  );
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const debug = queryParams.has('debug');
  if (debug && !formState.debug)
    formDispatch({
      type: FormActionType.TOGGLE_DEBUG,
    });

  return (
    <FormDataContext.Provider
      value={{ state: formState, dispatch: formDispatch }}
    >
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  const formDataContext = useContext(FormDataContext);
  if (!formDataContext)
    throw Error(
      'Form Data Context not found. Use this hook with FormDataContextProvider.',
    );
  return formDataContext;
};

export const useFormField = (fieldId: string) => {
  const formDataContext = useContext(FormDataContext);
  if (!formDataContext)
    throw Error(
      'Form Data Context not found. Use this hook with FormDataContextProvider.',
    );
  return formDataContext.state.formDefinition.fields.find(
    (field) => field.id === fieldId,
  );
};
