import { Typography } from '@material-ui/core';
import { useFormData } from '../context/FormState/FormDataContext';

function FormTitle() {
  const { state: formState } = useFormData();
  return (
    <Typography className="mt-2" variant="h3" align="center" style={{ fontSize: '28pt' }}>
      {formState.currentPage.config.title || formState.title}
    </Typography>
  );
}

export default FormTitle;
