import { ImageTool as ImageToolType } from '../../interfaces/FormFieldTypes';
import { memo } from 'react';

type Props = {
  field: ImageToolType;
};

const ImageTool = (props: Props) => {
  return (
    <div style={{ ...props.field.config.styling, overflow: 'hidden' }}>
      <img
        src={props.field.value}
        alt={props.field.config.variable}
        style={{
          height: '100%',
          width: '100%',
          opacity: props.field.config.opacity,
        }}
      />
    </div>
  );
};

export default memo(ImageTool);
