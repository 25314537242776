import { useContext, useEffect } from 'react';
import { useFormData } from './context/FormState/FormDataContext';
import Field from './form-fields/Field';
import { Box, Grid } from '@material-ui/core';
import { MessageBoxContext, MessageBoxStateActions, MessageBoxType } from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { FormField } from '../interfaces/FormFieldTypes';
import { useQuery } from 'react-query';
import { trackPromise } from 'react-promise-tracker';
import { useAppArgs } from './hooks/useAppArgs';
import { useAuthContext } from './context/AuthState/AuthContext';
import { getForm } from '../lib/formApi';
import { useFormUtils } from './hooks/useFormUtils';
import { FormActionType } from './context/FormState/form-state-reducer';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppConfig } from './context/AppState/AppContext';

interface NumberedUnit {
  num: number;
  unit: 'px' | 'in' | 'cm' | 'mm' | 'pt';
}

const numberedUnitToCssProperty = (numberedUnit: NumberedUnit) => `${numberedUnit.num}${numberedUnit.unit}`;

export const Form = (props: { showSubmit?: boolean; viewOnly?: boolean }) => {
  const { state: formState } = useFormData();
  const { debug } = useAppArgs();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const print = queryParams.get('print') === '1';

  const customCSS = formState.formDefinition.config?.customCSS;
  useEffect(() => {
    function sendApprovalFormReady() {
      if (!window.top) return;
      const layoutWrapperEl = document.querySelector('#layout-wrapper');
      console.log('layoutWrapper', layoutWrapperEl);
      if (!layoutWrapperEl) return;
      const layoutWrapperBox = layoutWrapperEl.getBoundingClientRect();
      console.log('layoutWrapperBox', layoutWrapperBox);
      window.top.postMessage(
        {
          source: 'forms-approval-iframe-ready',
          payload: {
            loaded: formState.loaded,
            width: layoutWrapperBox.width,
            height: layoutWrapperBox.height,
          },
        },
        '*',
      );
    }
    try {
      sendApprovalFormReady();
    } catch (error) {
      console.log(error);
    }

    if (!formState.loaded || !customCSS) return;

    const styleElement = document.createElement('style');
    document.head.appendChild(styleElement);
    const classNameMap = {
      'MuiFormControl-root': 'kmbs-form-control',
      'MuiTextField-root': 'kmbs-text-field',
      'MuiFormLabel-root': 'kmbs-form-label',
      'MuiInputLabel-root': 'kmbs-input-label',
      'MuiInputLabel-outlined': 'kmbs-input-label-outlined',
      'MuiInputBase-root': 'kmbs-input-base',
      'MuiOutlinedInput-root': 'kmbs-outlined-input',
      'MuiOutlinedInput-notchedOutline': 'kmbs-outlined-input-border',
      'MuiInputAdornment-root': 'kmbs-input-adornment',
      'MuiFormGroup-root': 'kmbs-form-group',
      'MuiCheckbox-root': 'kmbs-checkbox',
      'MuiFormControlLabel-label': 'kmbs-control-label',
      'MuiButton-root': 'kmbs-list-button',
      'MuiRadio-root': 'kmbs-radio',
      'MuiFormHelperText-root': 'kmbs-helper-text',
    };

    let massagedCustomCSS = customCSS;
    Object.entries(classNameMap).forEach(([key, value]) => {
      massagedCustomCSS = massagedCustomCSS.replaceAll(`.${value}`, `.${key}`);
    });
    styleElement.innerHTML = `.form-canvas { ${massagedCustomCSS} }`;

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [formState.loaded, customCSS]);

  if (!formState.loaded) return <></>;
  console.log('debug', debug);

  if (formState.advanced) {
    // Update to use canvas properties
    const pages = formState.formDefinition.pages;
    const currentPage = formState.currentPage.number;
    const selectedPage = pages![currentPage - 1];
    const pageStyling = selectedPage.config.styling!;
    const formConfigStyling = formState.formDefinition.config?.styling!;

    if (print) {
      // Render all pages when printing
      return (
        <>
          {pages?.map((page) => {
            return (
              <div
                className="flex-1 relative select-none"
                style={{
                  backgroundColor: pageStyling.backgroundColor,
                }}
              >
                <Box
                  style={
                    pageStyling.backgroundImage.enabled
                      ? {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundImage: `url('${pageStyling.backgroundImage.url}')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: pageStyling.backgroundImage.fitType === 'fit' ? '100% 100%' : 'contain',
                          backgroundPosition: pageStyling.backgroundImage.fitType === 'center' ? 'center' : '',
                          opacity: pageStyling.backgroundImage.opacity / 100,
                        }
                      : {}
                  }
                />
                <div className="flex-1 relative select-none">
                  <div
                    className="form-canvas flex-1 relative select-none w-full h-full"
                    style={{
                      width: numberedUnitToCssProperty(formConfigStyling.width as NumberedUnit),
                      // DEV NOTE::I have no idea why `minHeight` & `maxHeight` are required instead of simply `height`
                      // height: canvasHeight,
                      minHeight: numberedUnitToCssProperty(formConfigStyling.height as NumberedUnit),
                      maxHeight: numberedUnitToCssProperty(formConfigStyling.height as NumberedUnit),
                      backgroundColor: 'transparent',
                    }}
                  >
                    <div style={{ position: 'relative' }} className="text-xl w-full px-10 pt-5">
                      {formState.formDefinition.fields
                        .filter((field: FormField) => field.config.pageId === page.id)
                        .map((field: FormField) => (
                          <div
                            key={field.id + '_field'}
                            style={
                              formState.advanced
                                ? {
                                    position: 'absolute',
                                    top: field.config?.coordinates?.y,
                                    left: field.config?.coordinates?.x,
                                    border: debug ? '1px dotted red' : '',
                                    rotate: field.config?.styling?.rotate || '',
                                  }
                                : {}
                            }
                          >
                            <Field field={field} viewOnly={props.viewOnly} />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      // Render current page when just viewing
      return (
        <div
          className="flex-1 relative select-none"
          style={{
            backgroundColor: pageStyling.backgroundColor,
          }}
        >
          <Box
            style={
              pageStyling.backgroundImage.enabled
                ? {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url('${pageStyling.backgroundImage.url}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: pageStyling.backgroundImage.fitType === 'fit' ? '100% 100%' : 'contain',
                    backgroundPosition: pageStyling.backgroundImage.fitType === 'center' ? 'center' : '',
                    opacity: pageStyling.backgroundImage.opacity / 100,
                  }
                : {}
            }
          />
          <div className="flex-1 relative select-none">
            <div
              className="form-canvas flex-1 relative select-none w-full h-full"
              style={{
                width: numberedUnitToCssProperty(formConfigStyling.width as NumberedUnit),
                // DEV NOTE::I have no idea why `minHeight` & `maxHeight` are required instead of simply `height`
                // height: canvasHeight,
                minHeight: numberedUnitToCssProperty(formConfigStyling!.height as NumberedUnit),
                maxHeight: numberedUnitToCssProperty(formConfigStyling!.height as NumberedUnit),
                backgroundColor: 'transparent',
              }}
            >
              <div style={{ position: 'relative' }} className="text-xl w-full px-10 pt-5">
                {formState.formDefinition.fields
                  .filter((field: FormField) => field.config.pageId === selectedPage.id)
                  .map((field: FormField) => (
                    <div
                      key={field.id + '_field'}
                      style={
                        formState.advanced
                          ? {
                              position: 'absolute',
                              top: field.config?.coordinates?.y,
                              left: field.config?.coordinates?.x,
                              border: debug ? '1px dotted red' : '',
                              rotate: field.config?.styling?.rotate || '',
                            }
                          : {}
                      }
                    >
                      <Field field={field} viewOnly={props.viewOnly} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    if (print) {
      // Render all pages when printing
      const numPages = formState.numPages;
      const pageArray = Array.from({ length: numPages }, (_, i) => i + 1);
      return (
        <>
          {pageArray.map((i) => {
            return (
              <div className="text-xl w-full px-10 pt-5 page-break">
                <Grid container direction="column" alignItems="center" spacing={5}>
                  {formState.formDefinition.fields
                    .filter((field: FormField) => field.page === i)
                    .map((field: FormField) => (
                      <Field key={field.id + '_field'} field={field} viewOnly={props.viewOnly} />
                    ))}
                </Grid>
              </div>
            );
          })}
        </>
      );
    } else {
      // Render current page when viewing
      return (
        <div className="text-xl w-full px-10 pt-5">
          <Grid id="formGrid" container direction="column" alignItems="center" spacing={5}>
            {formState.formDefinition.fields
              .filter((field: FormField) => field.page === formState.currentPage.number)
              .map((field: FormField) => (
                <Field key={field.id + '_field'} field={field} viewOnly={props.viewOnly} />
              ))}
          </Grid>
        </div>
      );
    }
  }
};

type FormWrapperProps = {
  formID: string;
};
export const FormWrapper = ({ formID }: FormWrapperProps) => {
  const { dispatch: formDispatch } = useFormData();
  const { fallbackDomain, metadata } = useAppArgs();
  const { state: authState } = useAuthContext();
  const { state: appState } = useAppConfig();
  const { massageFormInput } = useFormUtils();
  const { dispatch } = useContext(MessageBoxContext);
  const history = useHistory();

  const { data, isLoading } = useQuery(
    ['formData', formID],
    async () => {
      const formData = await trackPromise(
        getForm(
          metadata.tenant.region,
          metadata.tenant.slug,
          fallbackDomain,
          formID,
          authState.token!,
          appState?.tenant?.id,
        ),
      );
      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }
      const massagedFormData = await massageFormInput(formData, {
        environment: new Map(),
        records: new Map(),
      });

      massagedFormData.originalFormDef = formData;
      formDispatch({
        type: FormActionType.POPULATE_FORM,
        payload: massagedFormData,
      });

      const sendJobPageRequest = () => {
        if (!window.top) {
          return;
        }
        window.top.postMessage(
          {
            source: 'forms-app-is-loaded-from-job',
            payload: {},
          },
          '*',
        );
      };
      sendJobPageRequest();
      return massagedFormData;
    },
    {
      enabled: !!formID && !!authState.token,
      onError: (err: AxiosError) => {
        dispatch({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );
  if (isLoading || !data) return <></>;
  return <Form />;
};

export default Form;
