import { SvgIconProps, SvgIcon } from "@material-ui/core";

export const SignatureTypeBackgroundIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 76 76" {...props}>
		<g id="Group_6957" data-name="Group 6957" transform="translate(-915.704 -480.501)">
			<path id="Path_7941" data-name="Path 7941" d="M0,0V65" transform="translate(978.5 486.5)" fill="none" stroke="#f2eff9" stroke-width="2" />
			<g id="Group_6956" data-name="Group 6956" transform="translate(929.907 496.496)">
				<path id="Path_6092" data-name="Path 6092" d="M14.413,38.223,9.422,52.779H3.6L19.4,6.2h7.07L42.693,53.195H36.455L31.464,38.639H14.413ZM29.8,33.648,25.226,20.34c-.832-2.911-1.664-5.822-2.5-8.318h0c-.832,2.5-1.664,5.406-2.5,7.9L15.661,33.232H29.8Z" transform="translate(-3.6 -6.2)" fill="#f2eff9" />
			</g>
		</g>
		<rect id="Rectangle_3176" data-name="Rectangle 3176" width="76" height="76" fill="none" />
	</SvgIcon>
);