import { useMemo } from 'react';
export const useAppArgs = () => {
  const search = window.location.search;
  let pathParams = window.location.pathname.split('/');
  pathParams.shift(); // remove first empty string
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  let processId = '',
    workflowId = '',
    nodeID = '',
    returnURL = '',
    returnToken = '',
    debug = false,
    mock = false,
    metadata: any = {},
    tenant = { id: '', region: '', slug: '', name: '', plan: '' },
    fallbackDomain = '',
    domain = '',
    urlId = '',
    formId = '',
    fileId = '',
    dropId = '',
    dropUsername = '',
    dropPassword = '',
    plan = '',
    mfpDomain = '';

  if (search) {
    const decodedQs = search.replace('?', '');
    const queryParams = new URLSearchParams(decodedQs);
    processId = queryParams.get('arn') ?? '';
    workflowId = queryParams.get('workflowId') ?? '';
    nodeID = queryParams.get('nodeId') ?? '';
    returnURL = queryParams.get('url') ?? '';
    returnToken = queryParams.get('token') ?? '';
    fileId = queryParams.get('fileId') ?? '';
    dropId = queryParams.get('dropId') ?? '';
    dropUsername = queryParams.get('dropUsername') ?? '';
    dropPassword = queryParams.get('dropPassword') ?? '';
    metadata = JSON.parse(decodeURIComponent(queryParams.get('metadata') ?? '{}'));
    if (metadata?.tenant) {
      tenant = metadata.tenant;
    }
    if (metadata?.tenant?.plan) {
      plan = metadata.tenant.plan;
    }
    debug = queryParams.has('debug');
    mock = queryParams.has('mock');
  }
  domain = window.location.hostname.split('.').slice(-2).join('.');

  if (domain) {
    if (domain === 'localhost') domain = 'stratus.lol';

    // use a fallback domain for the Forms Backend API's in case the request is from any one of the following domains.
    const altDomains: Record<string, string> = {
      localhost: 'stratus.lol',
      'scantripcloud.lol': 'stratus.lol',
      'scantripcloud.xyz': 'dodgestrat.us',
      'dispatcherstratus.com': 'scantripcloud.com',
    };
    fallbackDomain = altDomains[domain.toLowerCase()] || domain;
    if (plan === 'stratus') {
      switch (domain) {
        case 'scantripcloud.lol':
          mfpDomain = 'stratus.lol';
          break;
        case 'scantripcloud.xyz':
          mfpDomain = 'dodgestrat.us';
          break;
        case 'scantripcloud.com':
          mfpDomain = 'dispatcherstratus.com';
          break;
        default:
          mfpDomain = domain;
          break;
      }
    } else {
      switch (domain) {
        case 'stratus.lol':
          mfpDomain = 'scantripcloud.lol';
          break;
        case 'dodgestrat.us':
          mfpDomain = 'scantripcloud.xyz';
          break;
        case 'dispatcherstratus.com':
          mfpDomain = 'scantripcloud.com';
          break;
        default:
          mfpDomain = domain;
          break;
      }
    }
  }

  switch (pathParams[0]) {
    case 'internalform':
    case 'externalform':
      tenant.region = pathParams[1] || '';
      tenant.slug = pathParams[2] || '';
      urlId = pathParams[3] || '';
      tenant.name = pathParams[4] || '';
      tenant.id = pathParams[5] || '';
      tenant.plan = pathParams[6] || '';
      break;
    case 'editform':
    case 'view':
      tenant.region = pathParams[1] || '';
      tenant.slug = pathParams[2] || '';
      workflowId = pathParams[3] || '';
      processId = pathParams[4] || '';
      formId = pathParams[5] || '';
      break;
    default:
  }

  return {
    processId,
    workflowId,
    nodeID,
    metadata,
    returnURL,
    returnToken,
    debug,
    mock,
    domain,
    fallbackDomain,
    tenant,
    urlId,
    queryParams,
    pathParams,
    formId,
    fileId,
    dropId,
    dropUsername,
    dropPassword,
    plan,
    mfpDomain,
  } as const;
};
