const Form = {
  id: '31401327af274e8fbc475eee2e4d9189',
  title: 'Full Form Example',
  creator: 'user 1',
  editor: 'user 1',
  created: 1690810009418,
  updated: 1690810009418,
  formStatus: 'published',
  valid: true,
  formGroup: {
    id: '00000',
  },
  formDefinition: {
    targetMfpId: 'mfp_800x480',
    fields: [
      {
        type: 'pagebreak',
        id: 'vn01PYmrf6L-o0O9-yDD8',
        config: {
          variable: 'page1',
          title: 'My First Page',
          hideBack: false,
          hideNext: false,
          hideScan: false,
          hideHome: false,
          hideReturn: false,
          hidePageNumbers: false,
          showHelp: true,
          helpText: 'hello world',
        },
      },
      {
        type: 'text',
        id: 'lO5CFI-2cUnyK4Y4bPWC0',
        config: {
          variable: 'text1',
          title: 'Text Line field friendly name goes here',
          defaultValue: 'Hello',
          minLength: '1',
          maxLength: '50',
          required: true,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: true,
          helpText: 'Help text goes here.',
        },
      },
      {
        type: 'date',
        id: 'h3ew-M3oAOxbcg7ji-_M-',
        config: {
          variable: 'date1',
          title: 'Date and Time field friendly name',
          defaultValue: '',
          minValue: '2023-08-01T14:00:00.000Z',
          maxValue: '2023-08-05T16:30:00.000Z',
          returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
          defaultToNow: false,
          required: true,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: true,
          helpText: 'Help text goes here.',
        },
      },
      {
        type: 'date',
        id: 'h3ew-M3oAOxbcg7ji-_M-1',
        config: {
          variable: 'date1-ISO',
          title: 'Date and Time field friendly name',
          defaultValue: '',
          minValue: '08/01/2023 10:00 am',
          maxValue: '09/05/2023 12:30 pm',
          returnFormat: 'EEEE, MMMM dd, yyyy, hh:mm:ss',
          defaultToNow: false,
          required: true,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: true,
          helpText: 'Help text goes here.',
        },
      },
    ],
  },
};

export default Form;
