import Layout from './mfp/layout';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getForm, getFormUrlData, authWithFormsAppClient, getFormsSamlAppClient, getSamlProviderName } from '../lib/formApi';
import { AppContext } from './context/AppState/AppContext';
import { useFormUtils } from './hooks/useFormUtils';
import { trackPromise } from 'react-promise-tracker';
import { getUserPool } from '../lib/ntf';
import { getSigninUrl } from '../lib/cognitoApi';
import { AxiosError } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { MessageBoxContext, MessageBoxStateActions, MessageBoxType } from '@dispatcher-stratus/stratus-react';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { getTenantByPoolId, getWorkflow } from '../lib/wfxApi';
import { useAppArgs } from './hooks/useAppArgs';
import { useAuthApiContext, useAuthContext } from './context/AuthState/AuthContext';
import { Button } from '@material-ui/core';
import { useFormApi } from './hooks/useFormApi';
import Standby from './Standby';
import Loading from './Loading';
import Altcha from './Altcha';
import { getChallengeUrl, getVerifyUrl } from '../lib/altchaApi';

type Props = {
  children: React.ReactNode;
};
/**
 * Extracts the requireRecaptcha value from the nodes array of the given workflow object,
 * matching the id with the provided targetId.
 *
 * @param {any} data - The workflow object containing nodes array.
 * @param {string} targetId - The target node id to match.
 * @returns {boolean|null} - The requireRecaptcha value if found, otherwise null.
 */
const extractRequireRecaptcha = (data: any, targetId: string): boolean | null => {
  for (const node of data.nodes) {
    if (node.id === targetId) {
      return node.config.requireRecaptcha;
    }
  }
  return null;
};

export const FormDataCollection = (props: Props) => {
  const { state: auth } = useAuthContext();
  const { setToken } = useAuthApiContext();
  const { setAppConfig } = useContext(AppContext);
  const { state: formState, dispatch: formDispatch } = useContext(FormDataContext);
  const history = useHistory();
  const { submitExternalForm } = useFormApi();
  const { fallbackDomain, debug } = useAppArgs();
  const queryClient = useQueryClient();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const {
    urlId,
    region,
    slug,
  }: {
    urlId: string;
    region: string;
    slug: string;
  } = useParams();
  const [authUrl, setAuthUrl] = useState('');
  const { massageFormInput, getTimeDifference } = useFormUtils();
  const { t } = useTranslation();

  //MARK: QUERIES
  const { data: userPoolData } = useQuery(
    ['userPool', [slug]],
    async () => {
      const userPool = await trackPromise(getUserPool(slug, fallbackDomain, region));
      return userPool;
    },
    {
      enabled: !!fallbackDomain,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch user pool. Form url may be invalid`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['tenantData', slug],
    async () => {
      const { id, name, plan, timeZone } = await trackPromise(getTenantByPoolId(region, userPoolData.poolId));
      return { id, name, slug, region, plan, timezone: timeZone };
    },
    {
      enabled: !!userPoolData,
      onSuccess: async (tenant) => {
        setAppConfig({ tenant });
        const approxTimeOffset = await getTimeDifference(tenant.timezone, region);
        setAppConfig({ timeOffset: approxTimeOffset });
      },
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch tenant data.',
          },
        });
      },
    },
  );

  const { data: formUrlData, isLoading } = useQuery(
    ['formUrlData', urlId],
    async () => {
      const formUrlData = await trackPromise(getFormUrlData(urlId, region, fallbackDomain));

      if (formUrlData && 'anonymous' === formUrlData.formAuth) {
        const authResponse = await trackPromise(authWithFormsAppClient(slug, fallbackDomain));
        if (authResponse && authResponse.access_token) {
          setToken(authResponse.access_token);
        } else {
          dispatchMessage({
            type: MessageBoxStateActions.MESSAGE_BOX,
            payload: {
              open: true,
              boxType: MessageBoxType.Ok,
              title: t('Authentication error'),
              message: t(
                'This tenant is not configured to support anonymous authentication. Contact your Dispatcher Stratus administrator.',
              ),
            },
          });
        }
      }

      if (!formUrlData.formId || !formUrlData.workflowId) {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Node configuration not saved. No form present.',
          },
        });
      } else {
        setAppConfig({
          formId: formUrlData.formId,
          nodeId: formUrlData.nodeId,
          workflowId: formUrlData.workflowId,
          startUrl: formUrlData.url,
        });
      }
      return formUrlData;
    },
    {
      enabled: !!urlId,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch form url data.',
          },
        });
      },
    },
  );

  const { data: workflow } = useQuery(
    ['checkWorkflowStatus'],
    async () => {
      if (!formUrlData || !formUrlData.workflowId) {
        console.log('workflow id missing');
        history.push('/unavailable');
        return null;
      }

      const workflow = await trackPromise(getWorkflow(region, auth.token!, formUrlData.workflowId));
      if (workflow?.status !== 'running') {
        console.log('workflow not running or no access');
        history.push('/unavailable');
      }
      return workflow;
    },
    {
      enabled: !!formUrlData?.workflowId && !!auth.token,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('Error checking workflow status'),
            message: 'Failed to check workflow status.',
          },
        });
      },
    },
  );

  const { data: formData } = useQuery(
    ['formData', formUrlData?.formId],
    async () => {
      const formData = await trackPromise(
        getForm(region, slug, fallbackDomain, formUrlData?.formId!, auth.token!, formUrlData?.tenantId),
      );
      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }
      const massagedFormData = await massageFormInput(formData, {
        environment: new Map(),
        records: new Map(),
      });
      
      massagedFormData.originalFormDef = formData;
      formDispatch({
        type: FormActionType.POPULATE_FORM,
        payload: massagedFormData,
      });
      return massagedFormData;
    },
    {
      enabled: !!formUrlData?.formId && !!auth.token,
      onSuccess: (data) => {
        document.title = data.title + ' - Dispatcher Stratus';
      },
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  const { data: ssoPoolData } = useQuery(
    ['ssoUserPool', slug],
    async () => {
      const client = await trackPromise(getFormsSamlAppClient(region, fallbackDomain, slug))
      if (formUrlData?.formAuth === 'sso-non-stratus-user' || formUrlData?.formAuth === 'sso-stratus-user') {
        // This should always be true if this query ran
        const provider = await trackPromise(getSamlProviderName(region, fallbackDomain, formUrlData.formAuth, formUrlData.tenantId))
      return {...client, provider: provider}
      }

      return client
    },
    {
      enabled: !!formUrlData?.formAuth && !auth.token && (formUrlData?.formAuth === 'sso-non-stratus-user' || formUrlData?.formAuth === 'sso-stratus-user'),
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('Authentication error'),
            message: t(
              'Unable to authenticate',
            ),
          },
        });
      }
    }
  )

  const openWindow = useCallback(
    (url: string) => {
      const ref = window.open(url, undefined, 'popup');
      window.addEventListener('message', (event) => {
        if (event.data.source === 'forms-app-login' && event.data.payload.success) {
          setToken(event.data.payload.token);
          ref?.close();
        }
      });
    },
    [setToken],
  );

  //MARK: EFFECTS

  useEffect(() => {
    const state = history.location?.state as { refresh?: boolean };
    if (state?.refresh) {
      setAppConfig({ arn: '', processId: '', returnToken: '', returnUrl: '' });
      formDispatch({
        type: FormActionType.RESET_FORM,
      });
      queryClient.invalidateQueries('formUrlData');
      queryClient.invalidateQueries('formData');
      queryClient.invalidateQueries('checkWorkflowStatus');
      queryClient.invalidateQueries('workflowStart');
      queryClient.invalidateQueries('workflowInstance');
    }
  }, [history.location, formDispatch, queryClient, setAppConfig]);

  useEffect(() => {
    if (!auth.token && userPoolData && formUrlData && 'stratus-user' === formUrlData.formAuth) {
      const redirectUrl = getSigninUrl(userPoolData.clientId, slug, region, userPoolData.client.ClientSecret, formUrlData.formAuth);
      setAuthUrl(redirectUrl);
      if (window.self !== window.top) {
        openWindow(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }
    }

    if (!auth.token && formUrlData && 'anonymous' !== formUrlData.formAuth && ssoPoolData ) {
      const redirectUrl = getSigninUrl(ssoPoolData.clientId, slug, region, ssoPoolData?.secret, formUrlData.formAuth, ssoPoolData?.provider)
      setAuthUrl(redirectUrl)
      if (window.self !== window.top) {
        openWindow(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }
    }
  }, [auth.token, urlId, userPoolData, region, slug, openWindow, formUrlData, ssoPoolData]);
  debug && console.log({ formUrlData, formData, userPoolData });

  if (!auth.isAuthenticated && window.self !== window.top && formUrlData && 'anonymous' !== formUrlData.formAuth) {
    return <Standby onRetry={() => openWindow(authUrl)} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  // Extract requireRecaptcha from the workflow object using formUrlData.nodeId
  const requireRecaptcha =
    workflow && formUrlData && formUrlData.nodeId ? extractRequireRecaptcha(workflow, formUrlData.nodeId) : null;

  const captchaRequired = formUrlData?.formAuth === 'anonymous' && requireRecaptcha;

  return (
    <Layout hideActionBar hideHeader onSubmit={async () => {}}>
      <div className="flex flex-col flex-nowrap w-full">
        <div>{props.children}</div>
        {formState.loaded && formState.currentPage.number === formState.numPages && (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await trackPromise(submitExternalForm(formState));
              return false;
            }}
          >
            <div className="flex flex-row justify-center">
              <div className="flex flex-col justify-center my-2">
                <div className="flex my-1">
                  {captchaRequired && <Altcha challengeurl={getChallengeUrl} verifyurl={getVerifyUrl} hidelogo />}
                </div>
                <div className="flex flex-row">
                  <Button type="submit" disabled={!formState.valid} variant="contained" color="primary">
                    {t('Submit')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};
