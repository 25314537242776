import { useAppArgs } from './useAppArgs';
import { trackPromise } from 'react-promise-tracker';
import axios from '../../lib/axios';
import { useContext } from 'react';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import { useQuery } from 'react-query';

type Domain =
  | 'stratus.lol'
  | 'scantripcloud.lol'
  | 'dodgestrat.us'
  | 'scantripcloud.xyz'
  | 'scantripcloud.com'
  | 'dispatcherstratus.com'
  | ({} & string);
type Region =
  | 'us-east-1'
  | 'eu-central-1'
  | 'ap-northeast-1'
  | 'ca-central-1'
  | ({} & string);

export const useProcessData = () => {
  const { workflowId, processId, nodeID, metadata, fallbackDomain } =
    useAppArgs();
  const { dispatch: showMessage } = useContext(MessageBoxContext);
  const configFetcher = (url: string) =>
    trackPromise(axios.get(url)).then((res) => res.data.config.formId);
  const processFetcher = (url: string) =>
    trackPromise(axios.get(url)).then((res) => res.data.data.files);

  const getEnv = (domain: Domain, region: Region): string => {
    return `https://${region}.ntf.${domain}`;
  };

  const ntfUrl = getEnv(fallbackDomain, metadata.tenant.region);

  const configQuery = useQuery(
    'nodeConfig',
    () =>
      configFetcher(
        `${ntfUrl}/workflow/node/download?workflowId=${workflowId}&nodeId=${nodeID}&arn=${processId}`,
      ),
    {
      staleTime: Infinity,
      enabled: !!workflowId && !!nodeID && !!processId,
      retryOnMount: false,
      refetchOnMount: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error('node config query error', err);
        showMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.None,
            title: t('error formError'),
            message: t('error fetchConfig'),
          },
        });
      },
    },
  );

  const processQuery = useQuery(
    'processData',
    () =>
      processFetcher(
        `https://${
          metadata.tenant.region ?? 'us-east-1'
        }.metadata-api.${fallbackDomain}/api/v1/process/${workflowId}/${processId}`,
      ),
    {
      staleTime: Infinity,
      retryOnMount: false,
      enabled: !!workflowId && !!nodeID && !!processId,
      refetchOnMount: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error('process data query error', err);
        showMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.None,
            title: t('error formError'),
            message: t('error fetchProcess'),
          },
        });
      },
    },
  );

  return {
    data: {
      formID: configQuery.data,
      fileID: processQuery.data,
    },
    isLoading: configQuery.isLoading || processQuery.isLoading,
  };
};
