import { FormControl, FormLabel, Grid, Button, FormGroup, Typography } from '@material-ui/core';
import { ButtonListField as ButtonListFieldType, ButtonListOption } from '../../interfaces/FormFieldTypes';
import { useFormData } from '../context/FormState/FormDataContext';
import { FormActionType } from '../context/FormState/form-state-reducer';
import { memo, useEffect, useMemo } from 'react';
import { t } from 'i18next';

type Props = {
  field: ButtonListFieldType;
};

const ButtonListField = memo(({ field }: Props) => {
  const { state, dispatch } = useFormData();
  const fieldError = state.errors.get(field.id);

  const validationErorr = useMemo(() => {
    const { required } = field.config;
    let hasValue = field.value || field.config.options.some((opt) => opt.isChecked);
    if (!hasValue && required) return t('Please select an option');
    return '';
  }, [field.value, field.config]);

  useEffect(() => {
    if (field.config.readonly) return;
    dispatch({
      type: FormActionType.SET_FORM_ERROR,
      payload: {
        id: field.id,
        message: validationErorr,
      },
    });
  }, [field.config, field.id, validationErorr, dispatch]);

  function handleClick(option: ButtonListOption) {
    const options: ButtonListOption[] = [];
    field.config.options.forEach((opt) => {
      if (opt.id === option.id) options.push({ ...opt, isChecked: !opt.isChecked });
      else if (!field.config.multiSelect && opt.isChecked) options.push({ ...opt, isChecked: false });
      else options.push(opt);
    });

    dispatch({
      type: FormActionType.SET_FIELD_OPTIONS,
      payload: {
        id: field.id,
        options: options,
      },
    });
  }

  const { width, height, rotate, ...buttonStyling } = { ...field.config.styling };

  const labelPosition = (field.config.styling?.labelPosition ?? 'top') as string;
  const labelPositionStyling = {
    top: {
      top: 0,
      left: 0,
      transform: 'translate(0, -100%)',
    } as const,
    left: {
      left: 0,
      top: '50%',
      transform: 'translate(calc(-100% - 12px), -50%)',
    } as const,
    right: {
      right: 0,
      top: '50%',
      transform: 'translate(calc(100% + 12px), -50%)',
    } as const,
    bottom: {
      bottom: 0,
      left: 0,
      transform: 'translate(0, 100%)',
    } as const,
  }[labelPosition];

  return (
    <Grid container direction={'column'} justifyContent="flex-start" alignItems="center" item xs={12}>
      <FormLabel
        required={field.config.required}
        style={{
          position: 'absolute',
          maxWidth: 'none',
          fontSize: '0.75rem',
          transition: 'none',
          whiteSpace: 'nowrap',
          ...labelPositionStyling,
          ...field.config.styling?.labelStyling,
        }}
      >
        {field.config.title}
      </FormLabel>
      <FormControl
        style={{
          width: width,
          height,
          rotate,
          overflow: 'hidden',
        }}
      >
        <FormGroup className="break-all" row={!field.config.vertical}>
          {field.config.options.length > 0 ? (
            field.config.options
              .filter((opt) => opt.show)
              .map((opt) => (
                <Button
                  key={opt.id}
                  className={`flex mt-1 mb-1 mr-1 break-all`}
                  variant="contained"
                  style={{
                    ...buttonStyling,
                    opacity: opt.isChecked ? '70%' : '100%',
                    maxWidth: '200px',
                    maxHeight: '48px',
                    minWidth: '32px',
                    overflow: 'hidden',
                  }}
                  disabled={field.config.readonly}
                  onClick={() => handleClick(opt)}
                >
                  {opt.label}
                </Button>
              ))
          ) : (
            <Button variant="contained" className="pt-0 pb-0 w-5" />
          )}
        </FormGroup>
      </FormControl>

      <div className={`ml-0.5 ${labelPosition === 'bottom' ? 'mt-3' : ''}`}>
        <Typography variant="subtitle2" style={{ color: 'red' }}>
          {fieldError}
        </Typography>
      </div>
    </Grid>
  );
});

export default memo(ButtonListField);
