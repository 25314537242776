import { Button, createStyles, makeStyles } from '@material-ui/core';
import FirstPageButton from './FirstPageButton';
import PreviousPageButton from './PreviousPageButton';
import NextPageButton from './NextPageButton';
import LastPageButton from './LastPageButton';
import PageSelector from './PageSelector';
import React from 'react';
import { useFormData } from '../../context/FormState/FormDataContext';

const FooterStyles = makeStyles(() =>
  createStyles({
    footer: {
      backgroundColor: '#c2c2c2',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '1em',
    },
  }),
);

function FooterControls() {
  const { state: formState } = useFormData();
  const classes = FooterStyles();

  if (formState.numPages < 2) return <></>;

  return (
    <div className={classes.footer}>
      <FirstPageButton />
      <PreviousPageButton />
      <PageSelector />
      <NextPageButton />
      <LastPageButton />
    </div>
  );
}

interface NavigationButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export function NavigationButton({
  children,
  onClick,
  disabled = false,
}: NavigationButtonProps) {
  return (
    <Button
      onClick={onClick}
      className="p-0"
      variant="text"
      disabled={disabled}
      style={{ opacity: disabled ? 0.5 : 1.0 }}
    >
      {children}
    </Button>
  );
}

export default FooterControls;
