import { t } from 'i18next';
import { useFormData } from '../context/FormState/FormDataContext';
import { Chip } from '@material-ui/core';

const PageNumberChip = () => {
  const { state } = useFormData();

  return (
    <Chip
      label={`${t('Page')}: ${state.currentPage.number} / ${state.numPages}`}
    />
  );
};

export default PageNumberChip;
