const Form = {
  id: '8e95f34b59a94eecac385790ae4857b0',
  title: 'free-form-test-altcha',
  creator: 'konicaminoltamarketplace_60a507f5ff82a85efc5e1c2dbd4b90981f68ecdd',
  editor: 'konicaminoltamarketplace_60a507f5ff82a85efc5e1c2dbd4b90981f68ecdd',
  valid: true,
  advanced: true,
  metadataKeys:
    'text1,number1,textarea1,dropdown1,checkbox1,square1,circle1,triangle1,star1,diamond1,asterisk1,plus1,minus1,line1',
  created: 1716316264141,
  updated: 1717686555720,
  formStatus: 'published',
  formGroup: {
    id: 'ed956ae14e3e4a87bedfce09a4a7e097',
  },
  formDefinition: {
    targetMfpId: 'custom',
    config: {
      pageCount: 1,
      gridSize: 1,
      styling: {
        width: {
          num: 800,
          unit: 'px',
        },
        height: {
          num: 1200,
          unit: 'px',
        },
        backgroundColor: '#ffffffff',
      },
      authRequired: false,
      authType: 'anonymous',
      requireRecaptcha: true,
    },
    pages: [
      {
        id: 'dH88r3qnru_xofNbNRifP',
        config: {
          styling: {
            backgroundColor: '#CCFFF5',
            backgroundImage: {
              enabled: false,
              image: null,
              opacity: 100,
              fitType: 'fit',
            },
          },
        },
      },
      {
        id: 'eI88r3qnru_xofNbNRifQ',
        config: {
          styling: {
            backgroundColor: '#FFCCD6',
            backgroundImage: {
              enabled: false,
              image: null,
              opacity: 100,
              fitType: 'fit',
            },
          },
        },
      },
    ],
    fields: [
      {
        type: 'pagebreak',
        id: 'GVUTx5L-7GU3-bVgZk6Ln',
        config: {
          variable: 'page1',
          title: '',
          hideBack: false,
          hideNext: false,
          hideScan: false,
          hideHome: false,
          hideReturn: false,
          hidePageNumbers: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 0,
            y: 0,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 1,
            height: 1,
          },
        },
      },
      {
        type: 'text',
        id: 'MQOXM97RORiYZg8QEmqpf',
        config: {
          variable: 'text1',
          title: 'Text line field friendly name goes here',
          defaultValue: '',
          minLength: '0',
          maxLength: '1000',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 55,
            y: 76,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
          },
        },
      },
      {
        type: 'number',
        id: '_NmwJcUK2ZnqGr57gShBi',
        config: {
          variable: 'number1',
          title: 'Number field friendly name goes here',
          defaultValue: '',
          minValue: '0',
          maxValue: '99999999',
          required: false,
          readonly: false,
          mask: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 317,
            y: 135,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
          },
        },
      },
      {
        type: 'textarea',
        id: 'Ms70eVqmBJclylJNUHMYk',
        config: {
          variable: 'textarea1',
          title: 'Text area field friendly name goes here',
          defaultValue: '',
          minLength: '0',
          maxLength: '5000',
          required: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: true,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 55,
            y: 222,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 86,
          },
        },
      },
      {
        type: 'textarea',
        id: 'Ms70eVqmBJclylJNU5MYk',
        config: {
          variable: 'textarea1',
          title: 'Positionable textarea friendly name goes here',
          defaultValue: '',
          minLength: '0',
          maxLength: '5000',
          required: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 255,
            y: 422,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 86,
            labelPosition: 'left',
          },
        },
      },
      {
        type: 'dropdown',
        id: 'UNmGvge6d4k0g6jAzst5n',
        config: {
          variable: 'dropdown1',
          title: 'Drop-Down field friendly name goes here',
          defaultValue: '',
          required: false,
          readonly: false,
          multiSelect: false,
          buttonsList: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          options: [
            {
              id: 'VLhQaJ70kLb6UZwZ5iB_D',
              label: 'one',
              value: '1',
              show: true,
              isDefault: false,
            },
            {
              id: 'wdMOpdaYJJXISV9K6FPeY',
              label: 'two',
              value: '2',
              show: true,
              isDefault: false,
            },
          ],
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 374,
            y: 800,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
          },
        },
      },
      {
        type: 'checkbox',
        id: '9enGRkUEpPFYP8cC3sgem',
        config: {
          variable: 'checkbox1',
          title: "Check if you're cool",
          defaultValue: '',
          trueLabel: 'Yes',
          falseLabel: 'No',
          readonly: false,
          checked: false,
          buttonsList: false,
          excludeFromMetadata: false,
          showHelp: false,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 87,
            y: 440,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 24,
          },
        },
      },
      {
        type: 'square',
        id: 'Jg_Zq_a_zLDzkA2ovVJQc',
        config: {
          variable: 'square1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 52,
            y: 541,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 81,
            height: 87,
          },
        },
      },
      {
        type: 'circle',
        id: 'xS-R_NPuvvTI5QmWfkDol',
        config: {
          variable: 'circle1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 167,
            y: 524,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 89,
            height: 115,
          },
        },
      },
      {
        type: 'triangle',
        id: 'v-ax1V1j9-awse-WzZD-9',
        config: {
          variable: 'triangle1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 284,
            y: 532,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 112,
            height: 91,
          },
        },
      },
      {
        type: 'star',
        id: 'ouajMla_trwlyn1q449be',
        config: {
          variable: 'star1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 420,
            y: 515,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 96,
            height: 113,
          },
        },
      },
      {
        type: 'diamond',
        id: 'dad_TdNE4up_xcb3f3zvp',
        config: {
          variable: 'diamond1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 632,
            y: 527,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 124,
            height: 102,
          },
        },
      },
      {
        type: 'asterisk',
        id: 'Qu9u608vLLg_U5CjqHV_Y',
        config: {
          variable: 'asterisk1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 531,
            y: 522,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 91,
            height: 112,
          },
        },
      },
      {
        type: 'plus',
        id: 'DYJuDNWkWlVBlAvxAkKvZ',
        config: {
          variable: 'plus1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 144,
            y: 658,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 60,
            height: 60,
          },
        },
      },
      {
        type: 'minus',
        id: 'hTEW6i9TdM3odJYs4ebe9',
        config: {
          variable: 'minus1',
          excludeFromMetadata: false,
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 143,
            y: 701,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 60,
            height: 60,
          },
        },
      },
      {
        type: 'line',
        id: 'TpWRtcnRDG4whrWenDPxR',
        config: {
          variable: 'line1',
          excludeFromMetadata: false,
          pageId: 'eI88r3qnru_xofNbNRifQ',
          coordinates: {
            x: 249,
            y: 661,
          },
          styling: {
            rotate: '-90deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 4,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 16,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 104.4859758410897,
            height: 66.50364413252912,
          },
        },
      },
      {
        type: 'label',
        id: 'AgAySTUWcaPT5pho6veyC',
        config: {
          variable: 'label1',
          title:
            'Label goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes hereLabel goes here',
          pageId: 'eI88r3qnru_xofNbNRifQ',
          coordinates: {
            x: 342,
            y: 395,
          },
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'dashed',
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#c4c4c4ff',
            fontSize: 20,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 88,
          },
        },
      },
      {
        type: 'signature',
        id: 'UNmGvge6d4k0g6jAzst5b',
        config: {
          variable: 'signature1',
          title: 'Signature field friendly name goes here',
          defaultValue: '',
          required: false,
          readonly: false,
          excludeFromMetadata: false,
          showHelp: true,
          helpText: '',
          pageId: 'dH88r3qnru_xofNbNRifP',
          coordinates: {
            x: 274,
            y: 337,
          },
          minLength: '0',
          maxLength: '1000',
          drawSign: true,
          typeSign: true,
          uploadImageSign: true,
          styling: {
            rotate: '0deg',
            color: '#000000ff',
            backgroundColor: '#ffffff00',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 4,
            borderColor: '#c4c4c4ff',
            fontSize: 64,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecorationLine: 'none',
            textAlign: 'left',
            width: 360,
            height: 40,
          },
        },
      },
    ],
  },
};
export default Form;
